import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHistory, withRouter } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { dateFormatDdMmmYyyy } from '../utils/utils';
import { alertToast } from './Toast';
import styles from '../css/InterviewListTable.module.scss';

const InterviewListTable = ({ interviewList }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState('All');
  const [list, setList] = useState(interviewList || []);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);
    let filteredList = [];
    if (value === 'Mock Interview') {
      filteredList = list.filter(interview => interview.is_mock_interview);
    } else {
      filteredList = interviewList;
    }
    setList(filteredList);
  };

  const renderCompanyLogo = img => (
    <div
      style={{
        backgroundImage: `url(${img})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        minWidth: '5rem',
        maxWidth: '5rem',
        width: '5rem',
        maxHeight: '3rem',
        minHeight: '3rem',
        height: '3rem',
        backgroundRepeat: 'no-repeat',
      }}
    />
  );

  const mobileRenderCompanyLogo = img => (
    <div
      style={{
        backgroundImage: `url(${img})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        minWidth: '1.8rem',
        maxWidth: '1.8rem',
        width: '1.8rem',
        maxHeight: '1.8rem',
        minHeight: '1.8rem',
        height: '1.8rem',
        backgroundRepeat: 'no-repeat',
        display: 'inline-block',
      }}
    />
  );

  const handleRedirectToInterview = (interviewid, candidateid, status, slug, interviewStatus) => {
    if (status === 'New' && interviewStatus !== 'Expired') {
      history.push(`/interview/${interviewid}/${candidateid}/${slug}`);
    } else if (status === 'Evaluated') {
      history.push(`/user/assessment/${interviewid}/${candidateid}`);
    } else if (status === 'Responded') {
      alertToast('Your interview is currently being assessed. Please try again later!');
    } else if (status === 'Live') {
      history.push(`/interview/${interviewid}/${candidateid}/${slug}`);
    } else {
      alertToast('Unfortunately, the interview has expired or closed by the employer!');
    }
  };


  return (
    <div>
      {/* Large screen layout */}
      <div className={styles.dropdownSection}>
        <select value={selectedOption} onChange={handleChange} className={styles.dropdownFilter}>
          <option value="All">All</option>
          <option value="Mock Interview">Mock Interview</option>
        </select>
      </div>
      <div className={styles.interviewListContainer}>
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div className={styles.columnHeadings}>
              <span>{t('dashboardPanel.action')}</span>
            </div>
            <div className={styles.columnHeadings}>
              <span>{t('dashboardPanel.role')}</span>
            </div>
            <div className={styles.columnHeadings}>
              <span>{t('dashboardPanel.status')}</span>
            </div>
            <div className={styles.columnHeadings}>
              <span>{t('dashboardPanel.date')}</span>
            </div>
            <div className={styles.columnHeadings}>
              <span>{t('dashboardPanel.action')}</span>
            </div>
          </div>
          <div className={styles.tableBody}>
            {console.log('interviewList', interviewList)}
            {
              list.length === 0 ? (
                // Once intro video gets added here then we need to add pulled icon here
                <span>No interviews</span>
              ) : (
                list.map(interview => (
                  <div className={styles.tableRow}>
                    <button
                      type="button"
                      className={
                        classnames(styles.tableContent, styles.companyLogoName)
                      }
                      onClick={
                        () => handleRedirectToInterview(
                          interview.interviewid,
                          interview.candidateid,
                          interview.status,
                          interview.slug,
                          interview.interview_status,
                        )
                      }
                      disabled={interview.status === 'Responded'}
                    >
                      {renderCompanyLogo(interview.company_logo_url)}
                      <span>{interview.company_name}</span>
                    </button>
                    <div className={styles.tableContent}>
                      <span>
                        {
                          interview.is_mock_interview
                            ? (
                              <>
                                <span>
                                  {interview.interviewname}
                                </span>
                                <span style={{ color: '#656565' }}>
                                  {' (Mock)'}
                                </span>
                              </>
                            )
                            : interview.interviewname
                        }
                      </span>
                    </div>
                    <div className={styles.tableContent}>
                      <span>{interview.status}</span>
                    </div>
                    <div className={styles.tableContent}>
                      <span>{dateFormatDdMmmYyyy(interview.dateresponded)}</span>
                    </div>
                    <div>
                      {
                        (interview.interview_status === 'Expired'  //eslint-disable-line
                          && interview.status !== 'Evaluated')
                          ? (
                            <button
                              type="button"
                              className={styles.expiredButton}
                              onClick={() => handleRedirectToInterview(
                                interview.interviewid,
                                interview.candidateid,
                                interview.status,
                                interview.slug,
                                interview.interview_status,
                              )}
                            >
                              Expired
                            </button>
                          )
                          : (
                            interview.status === 'New' ? (
                              <button
                                type="button"
                                className={styles.recordNowButton}
                                onClick={() => handleRedirectToInterview(
                                  interview.interviewid,
                                  interview.candidateid,
                                  interview.status,
                                  interview.slug,
                                  interview.interview_status,
                                )}
                              >
                                {t('dashboardPanel.recordNow')}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className={
                                  interview.status === 'Responded' ? styles.processingButton : styles.viewDetailsButton
                                }
                                onClick={
                                  () => handleRedirectToInterview(
                                    interview.interviewid,
                                    interview.candidateid,
                                    interview.status,
                                    interview.slug,
                                    interview.interview_status,
                                  )
                                }
                                disabled={interview.status === 'Responded'}
                              >
                                {
                                  interview.status === 'Responded' ? t('dashboardPanel.processing') : t('dashboardPanel.viewDetails')
                                }
                              </button>
                            )
                          )
                      }
                    </div>
                  </div>
                ))
              )
            }

          </div>
        </div>
      </div>
      {/* Mobile screen layout */}
      <div className={styles.mobileScreenInterviewListContainer}>
        <div className={styles.mobileInterviewList}>
          {
            list.length === 0 ? (
              // Once intro video gets added here then we need to add pulled icon here
              <span>No interviews</span>
            ) : (
              list.map(interview => (
                <div className={styles.mobileInterviewDetails}>
                  <div className={styles.flexBox}>
                    <div className={styles.roleAndCompanyName}>
                      <div className={styles.interviewRole}>
                        {interview.interviewname}
                      </div>
                      <div className={styles.companyNameAndLogo}>
                        <div className={styles.companyLogo}>
                          {mobileRenderCompanyLogo(interview.company_logo_url)}
                        </div>
                        <div className={styles.interviewerCompanyName}>
                          {interview.company_name}
                        </div>
                      </div>
                    </div>
                    <div className={styles.interviewStatus}>
                      {interview.status}
                    </div>
                  </div>
                  <div className={styles.flexBox}>
                    <div className={styles.interviewDateSection}>
                      <div className={styles.dateText}>
                        Date
                      </div>
                      <div className={styles.interviewDate}>
                        {dateFormatDdMmmYyyy(interview.dateresponded)}
                      </div>
                    </div>
                    {
                      (interview.interview_status === 'Expired'   //eslint-disable-line
                        && interview.status !== 'Evaluated')
                        ? (
                          <button
                            type="button"
                            className={styles.expiredButton}
                            onClick={() => handleRedirectToInterview(
                              interview.interviewid,
                              interview.candidateid,
                              interview.status,
                              interview.slug,
                              interview.interview_status,
                            )}
                          >
                            Expired
                          </button>
                        )
                        : (
                          interview.status === 'New' ? (
                            <button
                              type="button"
                              className={styles.recordNowButton}
                              onClick={() => handleRedirectToInterview(
                                interview.interviewid,
                                interview.candidateid,
                                interview.status,
                                interview.slug,
                                interview.interview_status,
                              )}
                            >
                              {t('dashboardPanel.recordNow')}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className={
                                interview.status === 'Responded' ? styles.processingButton : styles.viewDetailsButton
                              }
                              onClick={
                                () => handleRedirectToInterview(
                                  interview.interviewid,
                                  interview.candidateid,
                                  interview.status,
                                  interview.slug,
                                  interview.interview_status,
                                )
                              }
                              disabled={interview.status === 'Responded'}
                            >
                              {
                                interview.status === 'Responded' ? t('dashboardPanel.processing') : t('dashboardPanel.viewDetails')
                              }
                            </button>
                          )
                        )
                    }
                  </div>
                </div>
              ))
            )
          }
        </div>
      </div>
    </div>
  );
};

InterviewListTable.defaultProp = {
  interviewList: [],
};

InterviewListTable.propTypes = {
  interviewList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withTranslation()(withRouter(InterviewListTable));
