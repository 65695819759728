// @flow
import React, { useState, useMemo, useEffect } from 'react';
import { getProgressColors } from '../utils/utils';
import styles from '../css/CandidateResumeContainer.module.scss';

const CandidateResumeInterface = ({ resumeContent }: { resumeContent: Object }) => {
  const { resume: resumeScores } = resumeContent;

  const [selectedPointId, setSelectedPointId] = useState(null);
  const [selectedTotalScore, setSelectedTotalScore] = useState(true);

  const { selectedDescription, selectedTitle } = useMemo(() => {
    if (selectedPointId) {
      setSelectedTotalScore(false);
      const selectedResumePoint = resumeScores.details.find(item => item.id === selectedPointId);
      return {
        selectedDescription: selectedResumePoint.description,
        selectedTitle: selectedResumePoint.heading,
      };
    }
    return {
      selectedDescription: '',
      selectedTitle: '',
    };
  }, [selectedPointId, resumeScores]);

  useEffect(() => () => setSelectedTotalScore(true), []);

  const handleDisplayDescription = (id) => {
    setSelectedPointId(id);
    setSelectedTotalScore(false);
  };

  const Slider = ({
    label,
    value,
    maxValue,
    id,
  }: {
    label: String,
    value: Number,
    maxValue: Number,
    id: Number,
  }) => {
    const percentage = (value / maxValue) * 100;
    return (
      <div
        className={styles.sliderContainer}
        style={{
          backgroundColor: `
              ${id === selectedPointId ? '#EEF0F5' : 'transparent'}
              `,
        }}
        onClick={() => handleDisplayDescription(id)}
        onKeyDown={() => { }}
        tabIndex={0}
        role="button"
        type="button"
      >
        <div className={styles.label}>
          {label}
        </div>
        <div className={styles.newFlex}>
          <div className={styles.center}>
            <div className={styles.slider}>
              <div
                className={styles.sliderFill}
                style={{
                  width: `${percentage}%`,
                  backgroundColor: getProgressColors(percentage),
                }}
              />
            </div>
          </div>
          <div className={styles.value}>
            {`${Math.floor(percentage)}/100`}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.testing}>
      <div>
        {
          resumeScores.details.map(item => (
            <Slider
              label={item.heading}
              value={item.score}
              maxValue={item.maximumScore}
              id={item.id}
            />
          ))
        }
      </div>
      <div
        className={styles.totalResumeScore}
        style={{
          backgroundColor: `
              ${selectedTotalScore ? '#EEF0F5' : 'transparent'}
              `,
        }}
        onClick={() => {
          setSelectedPointId(null);
          setSelectedTotalScore(true);
        }}
        onKeyDown={() => { }}
        tabIndex={0}
        role="button"
        type="button"
      >
        <div className={styles.totalScoreLabel}>
          Total Score
        </div>
        <div className={styles.totalScoreValue}>
          {`${resumeScores.totalResumeScore.score}/${resumeScores.totalResumeScore.maximumScore}`}
        </div>
      </div>
      {
        (selectedPointId || selectedTotalScore)
        && (
          <div className={styles.pointDescription}>
            <div className={styles.selectedTitle}>
              {selectedTotalScore
                ? resumeScores.totalResumeScore.heading
                : selectedTitle}
            </div>
            <div className={styles.selectedDesc}>
              {selectedTotalScore
                ? resumeScores.totalResumeScore.description
                : selectedDescription}
            </div>
          </div>
        )
      }
    </div>
  );
};

export default CandidateResumeInterface;
