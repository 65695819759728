import React from 'react';
import PropTypes from 'prop-types';
import CircularScoreChart from './CircularScoreChart';
import styles from '../css/CircularScoreChart.module.scss';

const ScoreCircle = (props) => {
  const {
    score,
    description,
  } = props;
  return (
    <div>
      <CircularScoreChart score={score} />
      <div className={styles.description}>
        {description}
      </div>
    </div>
  );
};

ScoreCircle.propTypes = {
  score: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
};

export default ScoreCircle;
