import React from 'react';
import { Link } from 'react-router-dom';

const Logout = () => (
  <div
    style={{
      padding: '1em',
    }}
  >
    You have logged out. Click
    {' '}
    <Link to="/candidate">here</Link>
    {' '}
    to login again.
  </div>
);

export default Logout;
