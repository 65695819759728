import alt from '../alt';
import CandidateActions from '../actions/CandidateActions';
import { capitaliseWord } from '../utils/utils';

class CandidateStore {
  constructor() {
    this.digitalProfile = null;
    this.digitalProfileError = null;
    this.isFetchingDigitalProfile = false;

    this.candidateAssessments = {};
    this.candidateAssessmentsError = null;
    this.isFetchingCandidateAssessment = false;
    this.isCoaching = false;
    this.isResumeValid = false;
    this.candidateSubscription = {
      candidateEmail: '',
      is_locked: true,
      is_subscribed: false,
    };
    this.isPaymentUnderProcess = false;
    this.autoplay = false;
    this.isAppDrawerOpen = true;
    this.candidateSummary = '';
    this.isCoachingCandidate = false;
    this.videoInterviewGatePrompt = false;
    this.videoInterviewGatePromptConditionalFlag = false;
    this.isCandidateProfieDeleted = false;
    this.deletingCandidateProfile = false;
    this.isNavigationDrawerOpen = false;
    this.reportDownloadProcessing = false;
    this.isNameFieldNonEditable = false;
    this.prefilledName = '';
    this.fetchingNameProcessingFlag = false;
    this.mockInterviewList = [{
      interviewname: 'Create new Interview',
      interviewid: null,
    }];

    this.mockInterviewCompanyData = null;
    this.mockInterviewSkills = [];
    this.fetchingMockInterview = false;

    this.bindListeners({
      handleIsNavigationDrawerOpen: CandidateActions.SET_IS_NAVIGATION_DRAWER_OPEN,
      handleDeletingCandidateProfile: CandidateActions.SET_DELETING_CANDIDATE_PROFILE,
      handleFetchDigitalProfileStart: CandidateActions.FETCH_DIGITAL_PROFILE_START,
      handleFetchDigitalProfileSuccess: CandidateActions.FETCH_DIGITAL_PROFILE_SUCCESS,
      handleFetchDigitalProfileFailure: CandidateActions.FETCH_DIGITAL_PROFILE_FAILURE,
      handleFetchDigitalProfileNotFound: CandidateActions.FETCH_DIGITAL_PROFILE_NOT_FOUND,
      handleFetchCandidateAssessmentStart: CandidateActions.FETCH_CANDIDATE_ASSESSMENT_START,
      handleFetchCandidateAssessmentSuccess: CandidateActions.FETCH_CANDIDATE_ASSESSMENT_SUCCESS,
      handleFetchCandidateAssessmentFailure: CandidateActions.FETCH_CANDIDATE_ASSESSMENT_FAILURE,
      handleFetchOrganizationId: CandidateActions.FETCH_ORGANIZATION_ID,
      handlefetchCoachingFlag: CandidateActions.FETCH_COACHING_FLAG,
      handleIsResumeValid: CandidateActions.SET_RESUME_VALID_FLAG,
      handleFetchCandidateSubscriptionSuccess: CandidateActions
        .FETCH_CANDIDATE_SUBSCRIPTION_SUCCESS,
      handleFetchCandidateSubscriptionFailure: CandidateActions
        .FETCH_CANDIDATE_SUBSCRIPTION_FAILURE,
      handleSetPaymentUnderProcess: CandidateActions.SET_PAYMENT_UNDER_PROCESS,
      handleSetAutoPlay: CandidateActions.SET_AUTO_PLAY,
      handleSetAppDrawerOpen: CandidateActions.SET_APP_DRAWER_OPEN,
      handleSetCoachingStatus: CandidateActions.SET_COACHING_STATUS,
      handleSetVideoInterviewGatePrompt: CandidateActions.SET_VIDEO_INTERVIEW_GATE_PROMPT,
      handleSetVideoInterviewGatePromptConditionalFlag: CandidateActions
        .SET_VIDEO_INTERVIEW_GATE_PROMPT_CONDITIONAL_FLAG,
      handleDeleteCandidateflag: CandidateActions.SET_IS_CANDIDATE_PROFILE_DELETED,
      handleIsReportDownloading: CandidateActions.IS_REPORT_DOWNLOADING,
      handleIsExistingCandidate: CandidateActions.SET_EXISTING_CANDIDATE,
      handleLoadingFirstNameFlag: CandidateActions.LOADING_FIRST_NAME,
      handleFetchMockInterviewSuccess: CandidateActions.FETCH_MOCK_INTERVIEW_SUCCESS,
      handleFetchMockInterviewCompanyData: CandidateActions.FETCH_MOCK_INTERVIEW_COMPANY_DATA,
      handleFetchMockInterviewSkillsSuccess: CandidateActions.FETCH_MOCK_INTERVIEW_SKILLS_SUCCESS,
      handleFetchingMockInterviews: CandidateActions.SET_FETCHING_MOCK_INTERVIEW,


    });
  }

  handleIsNavigationDrawerOpen(value) {
    this.isNavigationDrawerOpen = value;
  }

  handleDeletingCandidateProfile(value) {
    this.deletingCandidateProfile = value;
  }

  handleDeleteCandidateflag() {
    this.isCandidateProfieDeleted = true;
  }

  handleSetVideoInterviewGatePrompt(value) {
    this.videoInterviewGatePrompt = value;
  }

  handleSetVideoInterviewGatePromptConditionalFlag(value) {
    this.videoInterviewGatePromptConditionalFlag = value;
  }

  handleFetchDigitalProfileStart() {
    this.isFetchingDigitalProfile = true;
  }

  handlefetchCoachingFlag() {
    this.isCoaching = true;
  }

  handleFetchDigitalProfileSuccess(data) {
    const { interviews, introductionInterview } = data;
    const newInterviews = interviews ? interviews.map((i) => {
      const newInterview = { ...i };
      if (i.status === 'Evaluated') {
        newInterview.candidateStatus = 'Completed';
      } else {
        newInterview.candidateStatus = 'Incomplete';
      }
      return newInterview;
    }) : [];
    this.digitalProfile = {
      ...data,
      interviews: newInterviews,
      introductionInterview,
    };
    this.isFetchingDigitalProfile = false;
  }

  handleFetchDigitalProfileFailure(errorMessage) {
    this.digitalProfileError = errorMessage;
    this.isFetchingDigitalProfile = false;
  }

  handleFetchDigitalProfileNotFound() {
    this.digitalProfile = {
      esResponse: {
        profile: {},
        resumeSkills: [],
        workmapScores: [],
      },
      interviews: [],
      resumeSignedUrl: '',
    };
    this.isFetchingDigitalProfile = false;
  }

  handleFetchCandidateAssessmentStart() {
    this.isFetchingCandidateAssessment = true;
  }

  handleFetchCandidateAssessmentSuccess({ candidateId, data }) {
    const newData = { ...data };
    if (data.scores.workmap) {
      newData.scores.workmap = Object.entries(data.scores.workmap)
        .map(([skill, score]) => ({
          skill: capitaliseWord(skill),
          score,
        }));
    }
    this.candidateAssessments = {
      ...this.candidateAssessments,
      [candidateId]: newData,
    };
    this.isFetchingCandidateAssessment = false;
  }

  handleFetchCandidateAssessmentFailure(err) {
    this.candidateAssessmentsError = err;
    this.isFetchingCandidateAssessment = false;
  }

  handleFetchOrganizationId(orgId) {
    this.orgId = orgId;
  }

  handleIsResumeValid(data) {
    this.isResumeValid = data;
  }

  handleFetchCandidateSubscriptionSuccess(data) {
    this.candidateSubscription = data;
  }

  handleFetchCandidateSubscriptionFailure() {
    this.candidateSubscription = null;
  }

  handleSetPaymentUnderProcess(flag) {
    this.isPaymentUnderProcess = flag;
  }

  handleSetAutoPlay() {
    this.autoplay = true;
  }

  handleSetAppDrawerOpen(flag) {
    this.isAppDrawerOpen = flag;
  }

  handleSetCoachingStatus(data) {
    this.isCoachingCandidate = data;
  }

  handleIsReportDownloading(flag) {
    this.reportDownloadProcessing = flag;
  }

  handleIsExistingCandidate(data) {
    const {
      name,
      isNameEditable,
    } = data;
    this.prefilledName = name;
    this.isNameFieldNonEditable = isNameEditable;
  }

  handleLoadingFirstNameFlag(flag) {
    this.fetchingNameProcessingFlag = flag;
  }

  handleFetchMockInterviewSuccess(data) {
    console.log('List :::', data);
    this.mockInterviewList = data;
    this.mockInterviewList.concat({
      interviewname: 'Create new Interview',
      interviewid: null,
    });
  }

  handleFetchMockInterviewCompanyData(data) {
    this.mockInterviewCompanyData = data;
  }

  handleFetchMockInterviewSkillsSuccess(data) {
    this.mockInterviewSkills = data;
  }

  handleFetchingMockInterviews(value) {
    this.fetchingMockInterview = value;
  }
}
export default alt.createStore(CandidateStore, 'CandidateStore');
