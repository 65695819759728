// @flow
import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  jobName: string,
  companyName: string,
  companyLogoUrl: string,
  location: Object,
  description: ?string,
  source: ?string,
  jobLocation: ?string,
}
const JobHelmet = ({
  jobName,
  companyName,
  companyLogoUrl,
  location,
  description,
  source,
  jobLocation,
}: Props) => {
  let metaDescription = '';
  let title = '';
  let pageTitle = '';

  if (source === 'career-page') {
    pageTitle = `${companyName} Jobs | Interviewer.AI`;
    title = `${companyName} Careers and Employment | Interviewer.AI`;
    metaDescription = `Apply to ${description} ${companyName} jobs.
      Explore ${companyName} careers, ppenings, and employment options across different locations in your desired industry.
      Explore available jobs in ${companyName} today!`;
  } else if (source === 'application-page') {
    pageTitle = `${jobName} + Job | ${companyName}`;
    title = `${jobName} at ${companyName} (${jobLocation}) | Interviewer.AI`;
    metaDescription = `${description}`;
  } else {
    title = `${jobName} at ${companyName}`;
    metaDescription = description || `Explore a career with ${companyName} as ${jobName}`;
  }
  return (
    <Helmet>
      <title>{`${pageTitle}`}</title>
      <meta name="keywords" content={`jobs, career, ${companyName}, ${jobName}`} />
      <meta property="og:url" content={`${location.protocol}//${location.host}${location.pathname}`} />
      <meta property="og:title" content={`${title}`} />
      <meta property="og:image" content={companyLogoUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={metaDescription} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={`${title}`} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={companyLogoUrl} />
    </Helmet>
  );
};

export default JobHelmet;
