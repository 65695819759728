import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import getError from '../utils/error';
import InjuredIcon from '../assets/Injured.svg';
import styles from '../css/ErrorPage.module.scss';

const ErrorPage = ({ errorTitle, errorCode, showLinks }) => {
  const history = useHistory();
  const handleTryAgainClick = () => {
    window.location = '';
  };

  const handleGoHomeClick = () => {
    history.push('/candidate');
  };
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img className={styles.icon} src={InjuredIcon} alt="injured" />
        <h2>{errorTitle}</h2>
        {getError(errorCode)}
        {
          showLinks && (
            <div className={styles.actionBar}>
              <Button className={styles.button} variant="dark" onClick={handleTryAgainClick}>Try again</Button>
              <Button className={styles.button} variant="outline-dark" onClick={handleGoHomeClick}>Home</Button>
            </div>
          )
        }
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  errorTitle: PropTypes.string,
  errorCode: PropTypes.string.isRequired,
  showLinks: PropTypes.bool,
};

ErrorPage.defaultProps = {
  errorTitle: 'Oops! Something went wrong!',
  showLinks: true,
};

export default React.memo(ErrorPage);
