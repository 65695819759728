import i18n from '../locales/i18n';

const exists = i18n.exists.bind(i18n);
const t = i18n.t.bind(i18n);
const handleError = (code) => {
  const errorCode = `errors.${code}`;
  if (!exists(errorCode)) {
    // Send unknown code to sentry
    return t('errors.default');
  }

  return t(errorCode);
};

export default handleError;
