import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import styles from '../css/JobRecommendation.module.scss';
// import DashboardPurchaseBanner from '../assets/DashboardPurchaseBanner.jpg';
// import { ReactComponent as AiOrbitImageIcon } from '../assets/AiOrbitImageIcon.svg';
// import { ReactComponent as VideoCallIcon } from '../assets/VideoCallIcon.svg';
// import { ReactComponent as SearchEyeIcon } from '../assets/SearchEyeIcon.svg';
import CandidateActions from '../actions/CandidateActions';
import PriceButtonComponent from './PriceButtonComponent';
import CandidateStore from '../stores/CandidateStore';
import { currentCountryUsingIp } from '../utils/utils';
import { CANDIDATE_OVERVIEW_BANNER_INR, CANDIDATE_OVERVIEW_BANNER_USD } from '../utils/constants';


const JobRecommendation = (props) => {
  const [isLocked, setIsLocked] = useState(false);
  const [isAppDrawerOpen, setIsAppDrawerOpen] = useState(true);
  const [currentCountry, setCurrentCountry] = useState('usa');
  const [isCoachingCandidate, setIsCoachingCandidate] = useState(false);
  const [overviewBanner, setOverviewBanner] = useState(null);


  useEffect(() => {
    const { candidateSubscription, isCoaching } = props;
    if (candidateSubscription) {
      const { is_locked } = candidateSubscription; // eslint-disable-line
      setIsLocked(is_locked);
    }
    setIsCoachingCandidate(isCoaching);
  }, [props]);

  const channgeCandidateStore = (storeData) => {
    const { isAppDrawerOpen: isOpen } = storeData;
    setIsAppDrawerOpen(isOpen);
  };

  const getCurrentCountry = async () => {
    const country = await currentCountryUsingIp();
    setCurrentCountry(country);
  };

  useEffect(() => {
    CandidateStore.listen(channgeCandidateStore);
    getCurrentCountry();
    (async function () {
      setTimeout(() => {
        CandidateActions.fetchDigitalProfile();
      }, 0);
    }());
  }, []);

  useEffect(() => {
    if (currentCountry === 'india') {
      setOverviewBanner(CANDIDATE_OVERVIEW_BANNER_INR);
    } else {
      setOverviewBanner(CANDIDATE_OVERVIEW_BANNER_USD);
    }
  }, [currentCountry]);

  if (!isLocked || isCoachingCandidate) {
    return <div />;
  }

  return (
    <React.Fragment>
      <div className={styles.bannerContainerNew}>
        <img
          className={classnames(styles.bannerImage, !isAppDrawerOpen ? styles.bannerImageDrawerOpen : '')}
          src={overviewBanner}
          alt="purchase banner"
        />
        <PriceButtonComponent />
      </div>
    </React.Fragment>
  );
};

JobRecommendation.propTypes = {
  candidateSubscription: PropTypes.shape({
    is_locked: PropTypes.bool,
  }),
  isCoaching: PropTypes.bool.isRequired,
};

JobRecommendation.defaultProps = {
  candidateSubscription: {},
};

export default withTranslation()(withRouter(JobRecommendation));
