import auth0 from 'auth0-js';
import config from '../config/config';

class Auth {
  constructor() {
    const { AUTH0: { domain, clientID } } = config.default;

    this.auth = new auth0.WebAuth({
      domain,
      clientID,
      redirectUri: `${window.location.origin}/callback`,
      audience: `https://${domain}/api/v2/`,
      responseType: 'token id_token',
      scope: 'openid email profile read:current_user',
    });
  }

  isAuthenticated = () => {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  login = () => {
    this.auth.authorize();
  }

  handleAuthentication = async () => new Promise((resolve, reject) => {
    this.auth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        resolve();
      } else if (err) {
        console.log(err);
        reject(err);
      }
    });
  })

  getIdToken = () => localStorage.getItem('id_token')

  setSession = (authResult) => {
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime(),
    );

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('profile', JSON.stringify(authResult.idTokenPayload));
  }

  signOut() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('expires_at');
    this.auth.logout({
      clientID: config.default.AUTH0.clientID,
      returnTo: `${window.location.origin}/user/logout`,
    });
  }

  /* eslint-disable */
   getProfileInfo(key) {
    const profile = localStorage.getItem('profile');

    if (!profile) {
      console.log('No profile info found');
      return;
    }

    const profileJson = JSON.parse(profile);
    if (key in profileJson) {
      return profileJson[key];
    }
    return profileJson[`${config.AUTH0.namespace}${key}`];
  }
}

const auth = new Auth();
export default auth;
