// @flow
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as React from 'react';
import { HiInformationCircle } from 'react-icons/hi';
import styles from '../css/InfoTooltip.module.scss';

const iconSize = {
  sm: '1em',
  md: '1.5em',
  lg: '2em',
};

const InfoTooltip = ({
  tooltipContent, size, children, placement = 'bottom', ...props
}: {
  tooltipContent: React.Node,
  size: string,
  children: boolean,
  placement: string,
}) => (
  <OverlayTrigger
    placement={placement}
    overlay={(
      <Tooltip {...props}>
        {
          tooltipContent
        }
      </Tooltip>
    )}
    trigger={['hover', 'focus', 'click']}
  >
    {
      children || (
        <HiInformationCircle
          size={iconSize[size] || iconSize.sm}
          className={styles.icon}
        />
      )
    }
  </OverlayTrigger>
);

export default InfoTooltip;
