import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import { HiArrowUp } from 'react-icons/hi';
import CandidateActions from '../actions/CandidateActions';
import CandidateStore from '../stores/CandidateStore';
import { ReactComponent as HamIcon } from '../assets/HamburgerIcon.svg';
import { ReactComponent as TextBanner } from '../assets/DashboardTextBanner.svg';
import { ReactComponent as DesignImg } from '../assets/DesignPattern1.svg';
import { ReactComponent as DefaultLogo } from '../assets/DefaultProfile.svg';
import InterviewListTable from './InterviewListTable';
import JobRecommendation from './JobRecommendation';
import { getGreetings, isObjectEmpty } from '../utils/utils';
import styles from '../css/DashboardPanelConatainer.module.scss';
import AppDrawer from './AppDrawer';

const DashboardPanelConatainer = ({
  interviewList,
  profile,
  introductionInterview,
  candidateSubscription,
  isCoaching,
  candidateThumbnail,
}) => {
  const { name } = profile;
  const { t } = useTranslation();
  const [isRespondedIntroInterview, setIsRespondedIntroInterview] = useState(false);
  const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const history = useHistory();

  const checkIfIntroInterviewGiven = () => {
    const index = interviewList.some((element) => {
      if (
        element.interviewid === introductionInterview.interviewid
        && element.status !== 'New') {
        return true;
      }
      return false;
    });
    setIsRespondedIntroInterview(index);
  };

  const handleStoreChange = (store) => {
    const { isNavigationDrawerOpen: openNavbar } = store;
    setIsNavigationDrawerOpen(openNavbar);
  };

  useEffect(() => {
    CandidateStore.listen(handleStoreChange);
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
    return () => CandidateStore.unlisten(handleStoreChange);
  }, []);

  useEffect(() => {
    if (!isObjectEmpty(introductionInterview)) {
      checkIfIntroInterviewGiven();
    }
    /**
     * Ingnored because of following reason due to urgency
     *  "React Hook useEffect has a missing dependency:"
     */
    // eslint-disable-next-line
  }, [introductionInterview]);

  const handleRedirectToIntroVideo = () => {
    history.push(`/interview/${introductionInterview.interviewid}`);
  };

  const handleOpenNavigationBar = () => {
    CandidateActions.setIsNavigationDrawerOpen(!isNavigationDrawerOpen);
  };

  const closeNavigationbar = () => {
    if (isNavigationDrawerOpen) {
      CandidateActions.setIsNavigationDrawerOpen(false);
    }
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const renderCandidateThumbnail = () => (
    candidateThumbnail ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <img src={candidateThumbnail} className={styles.candidateThumbnail} alt="candidateThumbnail" />
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <DefaultLogo className={styles.noCandidateThumbnail} height="19" width="19" />
      </div>
    )
  );

  return (
    <React.Fragment>
      {/* Header */}
      {
        isNavigationDrawerOpen && (
          <div className={styles.mobileNavigationBar}>
            <AppDrawer />
          </div>
        )
      }
      <div className={styles.dashboardPanelConatainer}>
        <div>
          <div className={styles.largeScreenHeader}>
            <h4 className="f-w-bold">{t('dashboardPanel.dashboard')}</h4>
          </div>
        </div>
        <input type="hidden" value={isCoaching} className="coach" />
        <div className={styles.introVideoContainer}>
          <div className={styles.greetingDocument}>
            <p>
              <span className={styles.greetings}>
                {t('dashboardPanel.hey')}
                !
              </span>
              <span className={styles.candidateName}>{name}</span>
            </p>
            {
              isRespondedIntroInterview ? (
                <p className={styles.introText}>
                  {getGreetings()}
                </p>
              ) : (
                <p className={styles.introText}>
                  {t('dashboardPanel.introText1')}
                  <br />
                  {t('dashboardPanel.introText2')}
                </p>
              )
            }
          </div>
          {
            !isRespondedIntroInterview && (
              <button
                type="button"
                className={styles.introVideoRecordButton}
                onClick={handleRedirectToIntroVideo}
              >
                {t('dashboardPanel.recordVideo')}
              </button>
            )
          }
        </div>

        {/* <div className={styles.interviewListTableContainer}> */}
        <InterviewListTable
          interviewList={interviewList}
        />
        {/* </div> */}
        <div>
          <JobRecommendation
            candidateSubscription={candidateSubscription}
            isCoaching={isCoaching}
          />
        </div>
      </div>

      {/* Mobile dashboard */}
      <div
        className={styles.mobileDashboardPanelConatainer}
        style={{
          filter: isNavigationDrawerOpen ? 'blur(8px)' : 'none',
        }}
        onClick={closeNavigationbar}
        onKeyDown={closeNavigationbar}
        tabIndex={0}
        role="button"
        type="button"
      >
        {showTopBtn && (
          <div
            className={styles.upButton}
            onClick={goToTop}
            onKeyDown={goToTop}
            role="button"
            type="button"
            tabIndex={0}
          >
            <HiArrowUp />
          </div>
        )}
        <div
          style={{
            pointerEvents: isNavigationDrawerOpen ? 'none' : '',
          }}
        >
          <div className={styles.firstSection}>
            <div className={styles.mobileScreenHeader}>
              <div
                onClick={handleOpenNavigationBar}
                onKeyDown={handleOpenNavigationBar}
                tabIndex={0}
                role="button"
                type="button"
                className={styles.hamburgerMenuDiv}
              >
                <HamIcon />
              </div>
              <div className={styles.dashboardText}>
                {t('dashboardPanel.dashboard')}
              </div>
              {renderCandidateThumbnail()}
            </div>
            <input type="hidden" value={isCoaching} className="coach" />
            {
              isRespondedIntroInterview && (
                <div className={styles.introVideoContainer}>
                  <div className={styles.greetingDocument}>
                    <div>
                      <span className={styles.greetings}>
                        {t('dashboardPanel.hey')}
                        !
                      </span>
                      <span className={styles.candidateName}>{name}</span>
                    </div>
                    <div className={styles.introText}>
                      {getGreetings()}
                    </div>
                  </div>
                  <div className={styles.designSection}>
                    <DesignImg className={styles.designIcon1} />
                    <DesignImg className={styles.designIcon2} />
                  </div>
                </div>
              )
            }
            {
              !isRespondedIntroInterview && (
                <div className={styles.notRespondedIntroVideoContainer}>
                  <div className={styles.greetingDocument}>
                    <span>
                      <span className={styles.greetings}>
                        {t('dashboardPanel.hey')}
                        !
                      </span>
                      <span className={styles.candidateName}>{name}</span>
                    </span>
                    <div className={styles.introText}>
                      {t('dashboardPanel.introText1')}
                      <br />
                      {t('dashboardPanel.introText2')}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <TextBanner />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '1.2rem',
                    }}
                  >
                    <button
                      type="button"
                      className={styles.introVideoRecordButton}
                      onClick={handleRedirectToIntroVideo}
                    >
                      {t('dashboardPanel.recordVideo')}
                    </button>
                  </div>
                </div>
              )
            }
            <div className={styles.center}>
              <div>
                <div className={styles.InterviewsButton}>
                  {t('dashboardPanel.yourInterviews')}
                </div>
                <div className={styles.selectedUnderLine} />
              </div>
            </div>
          </div>

          {/* <div className={styles.interviewListTableContainer}> */}
          <InterviewListTable
            interviewList={interviewList}
          />
          {/* </div> */}
          <div>
            <JobRecommendation
              candidateSubscription={candidateSubscription}
              isCoaching={isCoaching}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};


DashboardPanelConatainer.propTypes = {
  interviewList: PropTypes.arrayOf(PropTypes.object).isRequired,
  profile: PropTypes.object.isRequired, // eslint-disable-line
  introductionInterview: PropTypes.object.isRequired, // eslint-disable-line
  candidateSubscription: PropTypes.object.isRequired, // eslint-disable-line
  isCoaching: PropTypes.bool.isRequired,
  candidateThumbnail: PropTypes.string.isRequired,
};

export default withTranslation()(withRouter(DashboardPanelConatainer));
