import alt from '../alt';
import CompanyActions from '../actions/CompanyActions';

class CompanyStore {
  constructor() {   //eslint-disable-line

    this.companiesDetails = {};
    this.fetchingCompaniesDetails = false;
    this.bindListeners({
      handleCompaniesDetails: CompanyActions.SET_COMPANIES_DETAILS,
      handleFetchingCompaniesDetails: CompanyActions.SET_FETCHING_COMPANIES_DETAILS,
    });
  }

  handleCompaniesDetails(data) {
    this.companiesDetails = data;
  }

  handleFetchingCompaniesDetails(data) {
    this.fetchingCompaniesDetails = data;
  }
}

export default alt.createStore(CompanyStore, 'CompanyStore');
