import React, { useState } from 'react';
import PropTypes from 'prop-types';
import chatBubble from '../assets/speech-bubble.svg';
import microphone from '../assets/microphone.svg';
import emotionIcon from '../assets/emotional.svg';
import MoveDown from '../assets/ArrowDown.svg';
import MoveUp from '../assets/ArraowUp.svg';

import styles from '../css/VideoAssessmentComp.module.scss';
import InfoTooltip from './InfoTooltip';
import { fetchScoreBreakDowns, getProgressColorClass, removeSpacesAndCamelCase } from '../utils/utils';

const ProgressBar = (props) => { // eslint-disable-line
  const [activeAccordion, setActiveAccordian] = useState({
    professionalism: false,
    positiveAttitude: false,
    communication: false,
    sociability: false,
  });

  const {
    text,
    value,
    progressBarStyle,
    tooltip,
    parsedCandidateAIScore,
  } = props;

  const handleFetchScoreLabel = (label, cf) => {
    const scoreNames = [
      {
        key: 'dressingScore',
        label: 'Dressing',
      },
      {
        key: 'hairTidinessScore',
        label: 'Hair Tidiness',
      },
      {
        key: 'bodyLanguageScore',
        label: 'Body Language',
      },
      {
        key: 'arousalScore',
        label: 'Arousal',
      },
      {
        key: 'communicationScore',
        label: 'Communication',
      },
      {
        key: 'articulationScore',
        label: 'Articulation',
      },
      {
        key: 'sentimentScore',
        label: 'Sentiments',
      },
      {
        key: 'emotionScore',
        label: 'Emotion',
      },
    ];

    if (!label) {
      console.log('Coming from :::', cf);
      return 'others';
    }

    const result = scoreNames.filter(scoreName => scoreName.key === label);

    return result[0] ? result[0].label : 'Others';
  };

  const getColorForProgressBar = Score => getProgressColorClass(Score);

  const handleSetActiveTab = (activeTab) => {
    setActiveAccordian({
      ...activeAccordion, [activeTab]: !activeAccordion[activeTab],
    });
  };

  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.flexContainer}>
        <p className={styles.progressBarTittle}>
          {text}
          <InfoTooltip
            tooltipContent={tooltip}
            className="tooltipContent"
            size="sm"
            placement="right"
          />
        </p>
        <div className={styles.accordianButtonContainer}>
          <p className={styles.progressValue}>
            {value}
            %
          </p>
          <button
            type="button"
            className={styles.chevronButton}
            onClick={() => handleSetActiveTab(text)}
          >
            <img
              src={activeAccordion[text] ? MoveUp : MoveDown}
              alt="open"
              className={styles.chevronIcon}
            />
          </button>
        </div>

      </div>
      <progress
        max={100}
        value={value}
        className={progressBarStyle}
      />

      {
        activeAccordion[text] && (
          <div className={styles.activeAccordianBody}>

            {
              fetchScoreBreakDowns(
                parsedCandidateAIScore,
                removeSpacesAndCamelCase(text.toLowerCase()),
              ).map(elem => (
                <div className={styles.breakDownSection}>
                  <span className={styles.breakDownLabel}>
                    {
                      handleFetchScoreLabel(Object.keys(elem)[0], '1')
                    }
                  </span>
                  <div className={styles.breakDown}>
                    <progress
                      max={100}
                      value={Object.values(elem)[0]}
                      className={`${getColorForProgressBar(Object.values(elem)[0])}`}
                    />

                    <span className={styles.breakDownPercentage}>
                      {Object.values(elem)[0].toFixed(0)}
                      %
                    </span>
                  </div>
                </div>
              ))
            }
          </div>
        )
      }

    </div>
  );
};

ProgressBar.defaultProps = {
  tooltip: '',
};


ProgressBar.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  progressBarStyle: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  parsedCandidateAIScore: PropTypes.object.isRequired  // eslint-disable-line
};


const VideoAssessmentScore = ({ aiScore }) => {
  const {
    communicationScore,
    articulationScore,
    sentimentScore,
    arousalScore,
    valenceScore,
    emotionScore,
  } = aiScore;
  return (
    <div className={styles.rootContainer}>
      <h2 className={styles.topText}>Video Interview Assessment</h2>
      <div className={styles.cardFlexContainer}>
        <div className={styles.cardContainer}>
          <div
            className={styles.card}
          >
            <div className={styles.imageContainer}>
              <img
                className={styles.cardIcon}
                src={chatBubble}
                alt="metrics"
              />
            </div>
            <p className={styles.cardText}>Text</p>
          </div>
          <ProgressBar
            value={Math.round(communicationScore)}
            text="Pace"
            progressBarStyle={styles.progressBar}
          />
          <ProgressBar
            value={Math.round(articulationScore)}
            text="Clarity"
            progressBarStyle={styles.progressBar}
          />
          <ProgressBar
            value={Math.round(sentimentScore)}
            text="Sentiment"
            progressBarStyle={styles.progressBar}
          />
        </div>
        <div className={styles.cardContainer}>
          <div
            className={styles.card}
          >
            <div className={styles.imageContainer}>
              <img
                className={styles.cardIcon}
                src={microphone}
                alt="metrics"
              />
            </div>
            <p className={styles.cardText}>Voice</p>
          </div>
          <div>
            <ProgressBar
              value={Math.round(arousalScore)}
              text="Energy"
              progressBarStyle={styles.progressBar}
            />
            <ProgressBar
              value={Math.round(valenceScore)}
              text="Positivity"
              progressBarStyle={styles.progressBar}
            />
          </div>
        </div>
        <div className={styles.cardContainer}>
          <div
            className={styles.card}
          >
            <div className={styles.imageContainer}>
              <img
                className={styles.cardIcon}
                src={emotionIcon}
                alt="metrics"
              />
            </div>
            <p className={styles.cardText}>Facial Expression</p>
          </div>
          <div>
            <ProgressBar
              value={Math.round(emotionScore)}
              text="Positive Emotion"
              progressBarStyle={styles.progressBar}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

VideoAssessmentScore.propTypes = {
  aiScore: PropTypes.shape({
    communicationScore: PropTypes.number,
    articulationScore: PropTypes.number,
    sentimentScore: PropTypes.number,
    arousalScore: PropTypes.number,
    valenceScore: PropTypes.number,
    emotionScore: PropTypes.number,
  }).isRequired,
};

export { VideoAssessmentScore, ProgressBar };
