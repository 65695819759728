import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import styles from '../../css/CandidateSettings.module.scss';
import CandidateStore from '../../stores/CandidateStore';
import CandidateActions from '../../actions/CandidateActions';
import { ReactComponent as NextIcon } from '../../assets/NextVector.svg';
import AppDrawer from '../../components/AppDrawer';
import { ReactComponent as HamIcon } from '../../assets/HamburgerIcon.svg';
import { CLOSING_ACCOUNT_RESPONSES } from '../../utils/constants';
import LoadingComponent from '../../components/LoadingComponent';
import { mxTrackEvent } from '../../utils/utils';

const CandidateSettings = () => {   //eslint-disable-line

  const { t } = useTranslation();
  const [candidateName, setCandidateName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [hasInterviews, setHasInterviews] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [candidateEmail, setCandidateEmail] = useState('');
  const [candidateResponse, setCandidateResponse] = useState('');
  const [candidateResponseError, setCandidateResponseError] = useState(false);
  const [processingDeletion, setProcessingDeletion] = useState(false);
  const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] = useState(false);

  const handleStoreChange = (store) => {
    const {
      digitalProfile,
      isFetchingDigitalProfile,
      deletingCandidateProfile,
      isNavigationDrawerOpen: openNavbar,
    } = store;
    if (isFetchingDigitalProfile) {
      return;
    }
    const { esResponse, interviews } = digitalProfile;
    const { profile } = esResponse;
    if (interviews.length !== 0) {
      setHasInterviews(true);
    }
    setIsNavigationDrawerOpen(openNavbar);
    setProcessingDeletion(deletingCandidateProfile);
    setCandidateName(profile.name);
    setCandidateEmail(profile.email);
    setIsLoading(isFetchingDigitalProfile);
  };

  useState(() => {
    CandidateStore.listen(handleStoreChange);
    const storeData = CandidateStore.getState();
    if (!storeData.digitalProfile || storeData.digitalProfileError) {
      CandidateActions.fetchDigitalProfile();
    } else {
      handleStoreChange(storeData);
    }
    return () => CandidateStore.unlisten(handleStoreChange);
  }, []);

  const onChange = (e) => {
    setCandidateResponse(e.target.value);
  };

  const handleToggleModal = () => {
    setToggleModal(true);
  };

  const handleCloseModal = () => {
    setToggleModal(false);
  };

  const handleMandatoryFields = () => {
    if (!candidateResponse) {
      setCandidateResponseError(true);
    }
  };

  const deleteCandidateProfile = async () => {
    await CandidateActions.deleteCandidate(candidateEmail);
    mxTrackEvent('Candidate Closing Account', {
      reason: candidateResponse,
    });
  };

  const handleOpenNavigationBar = () => {
    CandidateActions.setIsNavigationDrawerOpen(!isNavigationDrawerOpen);
  };

  const closeNavigationbar = () => {
    if (isNavigationDrawerOpen) {
      CandidateActions.setIsNavigationDrawerOpen(false);
    }
  };

  return (
    <div>
      {
        isNavigationDrawerOpen && (
          <div className={styles.mobileNavigationBar}>
            <AppDrawer />
          </div>
        )
      }
      <div
        className={styles.settingsParentContainer}
        style={{
          filter: isNavigationDrawerOpen ? 'blur(8px)' : 'none',
        }}
        onClick={closeNavigationbar}
        onKeyDown={closeNavigationbar}
        tabIndex={0}
        role="button"
        type="button"
      >
        <div
          className={styles.settingsSection}
          style={{
            pointerEvents: isNavigationDrawerOpen ? 'none' : '',
          }}
        >
          <div className={styles.largeNavigationBar}>
            <AppDrawer />
          </div>
          <div className={styles.settingsContent}>
            {
              isLoading ? (
                <div className={styles.loadingComponentDiv}>
                  <LoadingComponent type="rise" />
                </div>
              ) : (
                <div>
                  {/* For mobile screen */}
                  <div className={styles.mobileScreenHeader}>
                    <div
                      onClick={handleOpenNavigationBar}
                      onKeyDown={handleOpenNavigationBar}
                      tabIndex={0}
                      role="button"
                      type="button"
                      className={styles.hamburgerMenuDiv}
                    >
                      <HamIcon />
                    </div>
                    <div className={styles.settingsText}>
                      {t('candidatePortalSettings.settings')}
                    </div>
                  </div>
                  <h4 className={`f-w-bold ${styles.largeSettingsText}`}>
                    {t('candidatePortalSettings.settings')}
                  </h4>
                  <div className={styles.settingsGrid}>
                    <div className={styles.optionsContainer}>
                      <div className={styles.optionsSetion}>
                        <div className={styles.publicProfileText}>
                          {t('candidatePortalSettings.publicProfile')}
                        </div>
                        <div className={styles.profileVisibiltyText}>
                          {t('candidatePortalSettings.profileVisibility')}
                        </div>
                      </div>
                      <div className={styles.selectedOptionSection}>
                        <div className={styles.closeAccountOption}>
                          <div className={styles.closeAccountText}>
                            {t('candidatePortalSettings.closeAccount')}
                          </div>
                          <NextIcon width={14} height={14} stroke="#5F6981" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.selectedOptionContent}>
                      <div className={styles.closeCandidateAccountText}>
                        {t('candidatePortalSettings.closeAccount')}
                      </div>
                      <div className={styles.confirmationSection}>
                        <div className={styles.primaryConfirmationText}>
                          {candidateName && candidateName}
                          ,&nbsp;
                          {t('candidatePortalSettings.primaryConfirmationText')}
                        </div>
                        <div className={styles.secondaryConfirmationText}>
                          {t('candidatePortalSettings.secondaryConfirmationText')}
                        </div>
                      </div>
                      <div className={styles.reasonSection}>
                        <div className={styles.reasoningHeading}>
                          {t('candidatePortalSettings.reasoningHeading')}
                        </div>
                        {
                          (!candidateResponse && candidateResponseError) && (
                            <>
                              &nbsp;
                              <span style={{ color: '#FE5819', fontSize: '1em' }}>
                                (Please provide a valid reason)
                              </span>
                            </>
                          )
                        }
                        <div className={styles.reasonOptionsSection}>
                          {
                            CLOSING_ACCOUNT_RESPONSES.map(response => (
                              <label
                                className={styles.flex}
                                htmlFor="closing-response"
                              >
                                <input
                                  type="radio"
                                  name="closing-response"
                                  id={response}
                                  value={response}
                                  onChange={onChange}
                                  className={styles.checkboxInputField}
                                />
                                <span className={styles.centerResponse}>{response}</span>
                              </label>
                            ))
                          }
                        </div>
                      </div>
                      <div className={styles.feedbackSection}>
                        <span className={styles.primaryFeedbackText}>
                          {t('candidatePortalSettings.primaryFeedbackText')}
                        </span>
                        &nbsp;
                        <a className={styles.hyperLink} href="mailto:support@interviewer.ai">
                          {t('candidatePortalSettings.clickHere')}
                        </a>
                      </div>
                      <div className={styles.buttonSection}>
                        <div className={styles.buttonSectionPrimaryText}>
                          {t('candidatePortalSettings.buttonSectionPrimarytext')}
                        </div>
                        <div className={styles.confirmationButtons}>
                          {
                            hasInterviews ? (
                              <button
                                className={styles.deleteButton}
                                type="button"
                                onClick={
                                  candidateResponse
                                    ? handleToggleModal
                                    : handleMandatoryFields
                                }
                              >
                                {t('candidatePortalSettings.deleteButtonText')}
                              </button>
                            ) : (
                              <button
                                className={styles.deleteButton}
                                type="button"
                                onClick={candidateResponse
                                  ? deleteCandidateProfile
                                  : handleMandatoryFields
                                }
                              >
                                {
                                  processingDeletion
                                    ? 'Deleting...'
                                    : t('candidatePortalSettings.deleteButtonText')
                                }
                              </button>
                            )
                          }
                        </div>
                      </div>
                      <Modal
                        show={toggleModal}
                        onHide={handleCloseModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="contained-modal-title-vcenter">
                            {t('candidatePortalSettings.confirm')}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className={styles.secondaryConfirmationText}>
                            {t('candidatePortalSettings.modalConfirmtext')}
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            className={styles.confirmButton}
                            type="button"
                            onClick={deleteCandidateProfile}
                          >
                            {
                              processingDeletion
                                ? 'Deleting...'
                                : t('candidatePortalSettings.confirm')
                            }
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(withRouter(CandidateSettings));
