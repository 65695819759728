import React from 'react';
import auth from '../../../auth/auth';

const Login = () => {
  const handleOnLoginClick = () => {
    auth.login();
  };

  return (
    <div
      onClick={handleOnLoginClick()}
      onKeyDown={handleOnLoginClick()}
      tabIndex={0}
      role="button"
      type="button"
    />
  );
};

export default Login;
