import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

// import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import styles from '../../../css/DigitalProfile.module.scss';
// import InterviewListComponent from '../../../components/InterviewListComponent';
// import PanelTitle from '../../../components/PanelTitle';
// import WorkMapDisplay from './WorkMapDisplay';
// import ProfileInfoCard from '../../../components/ProfileInfoCard';
import LoadingComponent from '../../../components/LoadingComponent';
import CandidateActions from '../../../actions/CandidateActions';
import CandidateStore from '../../../stores/CandidateStore';
import ErrorPage from '../../ErrorPage';
// import { isObjectEmpty } from '../../../utils/utils';
import AppDrawer from '../../../components/AppDrawer';
import DashboardPanelConatainer from '../../../components/DashboardPanelContainer';
import { isObjectEmpty, getSubscriptionIsLocked, mxTrackEvent } from '../../../utils/utils';
import CandidatePersonalPersonalInfo from '../../../components/CandidatePersonalInfoContainer';
import Auth from '../../../auth/auth';


const DigitalProfile = () => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [interviewList, setInterviewList] = useState([]);
  // const [resumeUrl, setResumeUrl] = useState('');
  const [profile, setProfile] = useState({});
  // const [workmapScores, setWorkMapScores] = useState([]);
  // const [resumeSkills, setResumeSkills] = useState([]);
  // const [thumbnail, setThumbnail] = useState();
  const [introductionInterview, setIntroductionInterview] = useState({});
  const [digitalprofile, setDigitalProfile] = useState(null);
  const [candidateSubscription, setCandidateSubscription] = useState(null);
  const [isCoaching, setIsCoaching] = useState(false);

  const handleStoreChange = (store) => {
    const {
      digitalProfile,
      isFetchingDigitalProfile,
      digitalProfileError,
      candidateSubscription: subscription,
      isCoachingCandidate,
    } = store;
    if (isFetchingDigitalProfile) {
      return;
    }
    if (digitalProfileError) {
      setError(digitalProfileError);
      return;
    }
    const {
      esResponse,
      interviews,
      // resumeUrl: resumeSignedUrl,
      // thumbnail: candidateThumbnail,
      introductionInterview, // eslint-disable-line
    } = digitalProfile;
    const {
      profile: esProfile,
      // resumeSkills: esResumeSkills,
      // workmapScores: esWorkmapScores,
    } = esResponse;

    setIsLoading(isFetchingDigitalProfile);
    setInterviewList(interviews);
    setDigitalProfile(digitalProfile);
    setIsCoaching(isCoachingCandidate);

    // setWorkMapScores(esWorkmapScores);
    setProfile(esProfile);
    // setResumeUrl(resumeSignedUrl);
    // setResumeSkills(esResumeSkills);
    // setThumbnail(candidateThumbnail);
    setIntroductionInterview(introductionInterview);
    setCandidateSubscription(subscription);
  };

  // const checkSubscriptionExpiry = async () => {
  // };

  useEffect(() => {
    if (!Auth.isAuthenticated()) {
      Auth.login();
      return true;
    }
    CandidateStore.listen(handleStoreChange);
    const storeData = CandidateStore.getState();
    CandidateActions.fetchSubscription();
    CandidateActions.fetchCandidateCoachingStatus();
    if (!storeData.digitalProfile || storeData.digitalProfileError) {
      CandidateActions.fetchDigitalProfile();
    } else {
      handleStoreChange(storeData);
    }

    return () => CandidateStore.unlisten(handleStoreChange);
  }, []);

  useEffect(() => {
    if (candidateSubscription && !isObjectEmpty(candidateSubscription)) {
      const { expiry_date: expiryDate, is_locked: isLocked } = candidateSubscription;
      setTimeout(async () => {
        const triggerLockAccount = await getSubscriptionIsLocked(expiryDate);
        if (triggerLockAccount && !isLocked) {
          CandidateActions.updateIsLocked(true);
          const { email } = profile;
          mxTrackEvent('Candidate subscription expired', {
            email,
          });
        }
      }, 0);
    }
    /**
     * Ingnored because of following reason due to urgency
     *  "React Hook useEffect has a missing dependency:"
     */
    // eslint-disable-next-line
  }, [candidateSubscription]);

  if (error) {
    return (
      <div>
        <p className={styles.errorMessage}>
          {error}
        </p>
      </div>
    );
  }

  if (isLoading) {
    return <LoadingComponent type="rise" />;
  }

  if (Object.keys(profile).length === 0 && interviewList.length === 0) { // no candidate data
    Sentry.captureMessage('404_CANDIDATE_NOT_AVAILABLE went wrong');
    return (
      <ErrorPage
        errorTitle="No data found"
        errorCode="404_CANDIDATE_NOT_AVAILABLE"
        showLinks={false}
      />
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className={styles.appContent}>
        <div className={styles.dashboardContainer}>
          <AppDrawer />
          <DashboardPanelConatainer
            interviewList={interviewList}
            profile={profile}
            introductionInterview={introductionInterview}
            candidateSubscription={candidateSubscription}
            isCoaching={isCoaching}
          />
          {/* <div style={divStyle}>three</div> */}
          {

            digitalprofile && (
              <CandidatePersonalPersonalInfo digitalprofile={digitalprofile} />
            )
          }
        </div>
      </div>
      <div className={styles.mobileAppContent}>
        <DashboardPanelConatainer
          interviewList={interviewList}
          profile={profile}
          introductionInterview={introductionInterview}
          candidateSubscription={candidateSubscription}
          isCoaching={isCoaching}
          candidateThumbnail={digitalprofile && digitalprofile.thumbnail}
        />
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(DigitalProfile);
