import React from 'react';
import { RingLoader, RiseLoader } from 'react-spinners';
import { css } from 'react-emotion';
import PropTypes from 'prop-types';


const LoadingComponent = ({
  customCss,
  ringSize = 90,
  type = 'rise',
}) => {
  const cssStyle = customCss || css`
    position: fixed !important;
    top: calc(50% - 45px);
    left: calc(50% - 45px);
  `;

  if (type === 'rise') {
    return (
      <RiseLoader
        color="#6196fe"
        className={cssStyle}
        size={15}
        width={50}
      />
    );
  }
  return (
    <RingLoader
      color="#6196fe"
      className={cssStyle}
      size={ringSize}
    />
  );
};

LoadingComponent.propTypes = {
  customCss: PropTypes.shape({}),
  ringSize: PropTypes.number,
  type: PropTypes.string,
};

LoadingComponent.defaultProps = {
  customCss: css`
  position: fixed !important;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
`,
  ringSize: 90,
  type: 'rise',
};


export default LoadingComponent;
