import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import pl from './pl';
import zh from './zh';
import es from './es';
import id from './id';
import fr from './fr';
import vi from './vi';
import de from './de';


i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    lng: 'en-US',
    fallbackLng: 'en-US',
    debug: true,
    resources: {
      'en-US': {
        translation: en,
      },
      pl: {
        translation: pl,
      },
      'zh-CN': {
        translation: zh,
      },
      'es-ES': {
        translation: es,
      },
      'id-ID': {
        translation: id,
      },
      'fr-FR': {
        translation: fr,
      },
      'vi-VN': {
        translation: vi,
      },
      'de-DE': {
        translation: de,
      },
    },
  });

export default i18n;
