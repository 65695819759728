import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { withRouter, useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { successToast } from './Toast';
import styles from '../css/PersonalInfo.module.scss';
import { ReactComponent as DefaultLogo } from '../assets/DefaultProfile.svg';
import { ReactComponent as LocationIcon } from '../assets/LocationIcon.svg';
import { ReactComponent as ResumeIcon } from '../assets/ResumeIcon.svg';
import { ReactComponent as LinkedinIcon } from '../assets/LinkedinIcon.svg';
import { ReactComponent as EmailIcon } from '../assets/EmailIcon.svg';
import { ReactComponent as PhoneIcon } from '../assets/PhoneIcon.svg';
import { ReactComponent as CopyIcon } from '../assets/CopyIcon.svg';
import { ReactComponent as ShareIcons } from '../assets/ShareProfileIcons.svg';
import { ReactComponent as DisableShareIcons } from '../assets/DisabledShareProfileIcons.svg';
import { ReactComponent as DefaultIcon } from '../assets/DefaultLogo.svg';
import { ReactComponent as PlayIcon } from '../assets/PlayIcon.svg';
import { ReactComponent as PauseIcon } from '../assets/PauseIcon.svg';
import ClipLoadingComponent from './ClipLoadingComponent';
import CandidateActions from '../actions/CandidateActions';
import clientConfig from '../config/config';
import CandidateStore from '../stores/CandidateStore';
import ScoreCircle from './ScoreCircle';
import { BLURED_SCORE_IMAGE } from '../utils/constants';
import { mxTrackEvent } from '../utils/utils';


const CandidatePersonalPersonalInfo = (props) => {
  const {
    shortSummary,
    shortSkills,
    interestedCandidateSection,
    digitalprofile,
  } = props;
  const history = useHistory();
  const videoRef = useRef();
  const introductionVideoUrlId = clientConfig.default.INTRODUCTION_INTERVIEW;
  const [selectedPersonalInfoOption, setSelectedPersonalInfoOption] = useState(true);
  const [selectedIntroVideoOption, setSelectedIntroVideoOption] = useState(false);
  const [selectedScore, setSelectedScore] = useState(false);
  const [candidateName, setCandidateName] = useState('');
  const [linkedlin, setLinkedin] = useState(null);
  const [resume, setResume] = useState(null);
  const [candidateEmail, setCandidateEmail] = useState(null);
  const [candidateThumbnail, setCandidateThumbnail] = useState(null);
  const [location, setLocation] = useState(null);
  const [candidatePhonenumber, setCandidatePhonenumber] = useState(null);
  const [candidateSummary, setCandidateSummary] = useState(null);
  const [candidateResumeSkills, setCandidateResumeSkills] = useState([]);
  const [introductionInterview, setIntroductionInterview] = useState(false);
  const [candidateId, setCandidateId] = useState(null);
  const [videos, setVideos] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoStatus, setVideoStatus] = useState('pause');
  const [progressBarTime, setProgressBarTime] = useState(0);
  const [assessment, setAssessment] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [currentQuestionText, setCurrentQuestionText] = useState('');
  const [workmapScores, setWorkmapScores] = useState([]);
  const [workmapColorCodes, setWorkmapColorCodes] = useState([]);
  const [aiScores, setAIScores] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [moreToggle, setMoreToggle] = useState(false);
  const [candidateIntroProfileLink, setCandidateIntroProfileLink] = useState('');
  const [isLockedDetails, setIsLockedDetails] = useState(true);
  const [isCoaching, setIsCoaching] = useState(false);
  const [fetchingCandidateAssessment, setFetchingCandidateAssessment] = useState(false);


  const handleStoreState = (store) => {
    const {
      candidateAssessments,
      candidateSubscription,
      isCoachingCandidate,
      isFetchingCandidateAssessment,
    } = store;
    const { is_locked: isLocked } = candidateSubscription;
    setFetchingCandidateAssessment(isFetchingCandidateAssessment);
    setAssessment(candidateAssessments);
    setCandidateIntroProfileLink(digitalprofile.introductionInterviewUrl);
    setCandidateName(digitalprofile.esResponse.profile.name);
    setResume(digitalprofile.resumeUrl);
    setCandidateEmail(digitalprofile.esResponse.profile.email);
    setCandidateThumbnail(digitalprofile.thumbnail);
    setLocation(digitalprofile.esResponse.profile.location);
    setCandidatePhonenumber(digitalprofile.esResponse.profile.phone);
    setCandidateSummary(digitalprofile.esResponse.profile.summary);
    setCandidateResumeSkills(digitalprofile.esResponse.resumeSkills);
    setLinkedin(digitalprofile.esResponse.profile.linkedinURL);
    setWorkmapScores(digitalprofile.esResponse.workmapScores);
    setIsLockedDetails(isLocked);
    setIsCoaching(isCoachingCandidate);
    digitalprofile.interviews.forEach((interview) => {
      if (interview.interviewid === introductionVideoUrlId && interview.status === 'Evaluated') {
        setIntroductionInterview(true);
        setCandidateId(interview.candidateid);
      }
    });
  };

  useEffect(() => {
    CandidateStore.listen(handleStoreState);
    const { interviews } = digitalprofile;
    // need to look for an alternative
    setTimeout(() => { // Run after dispatcher has finished
      interviews.forEach((interview) => {
        if (interview.interviewid === introductionVideoUrlId) {
          CandidateActions.fetchCandidateAssessment(interview.candidateid, interview.interviewid);
        }
      });
    }, 0);
    if (digitalprofile && digitalprofile.introductionInterviewUrl) {
      setSelectedPersonalInfoOption(false);
      setSelectedIntroVideoOption(true);
      setSelectedScore(false);
    }

    return () => CandidateStore.unlisten(handleStoreState);
    /**
     * Ingnored because of following reason due to urgency
     *  "React Hook useEffect has a missing dependency:"
     */
    // eslint-disable-next-line
  }, []);

  const getRandomColor = () => {
    const o = Math.round;
    const r = Math.random;
    const s = 255;
    const color = `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},0.3)`;
    return color;
  };

  useEffect(() => {
    if (candidateId && assessment[candidateId]) {
      const candidate = assessment[candidateId];
      setCurrentQuestionText(candidate.videos[selectedQuestionIndex].text);
      setVideoUrl(candidate.videos[selectedQuestionIndex].video.signedUrl);
      setVideos(candidate.videos);
      setAIScores(candidate.scores.aiScores);
      const colorArray = [];
      workmapScores.map(() => colorArray.push(getRandomColor()));
      setWorkmapColorCodes(colorArray);
    }
    /**
     * Ingnored because of following reason due to urgency
     *  "React Hook useEffect has a missing dependency:"
     */
    // eslint-disable-next-line
  }, [candidateId, assessment]);

  const getAverageScore = (scores) => {
    const attributes = Object.values(scores).filter(a => a !== undefined);
    let totalScore = 0;
    if (attributes.length > 0) {
      totalScore = attributes.reduce((total, currentScore) => {
        if (currentScore !== undefined) {
          return total + currentScore;
        }
        return total;
      }, 0);
      totalScore /= attributes.length;
    }

    return {
      attributes: attributes.length,
      totalScore,
    };
  };

  const handlePersonalInfo = () => {
    setSelectedPersonalInfoOption(true);
    setSelectedIntroVideoOption(false);
    setSelectedScore(false);
  };

  const handleIntroVideo = () => {
    setSelectedPersonalInfoOption(false);
    setSelectedIntroVideoOption(true);
    setSelectedScore(false);
  };

  const handleScore = () => {
    setSelectedPersonalInfoOption(false);
    setSelectedIntroVideoOption(false);
    setSelectedScore(true);
  };

  const handleDefaultIntroVideo = () => {
    history.push(`/interview/${introductionVideoUrlId}`);
  };

  const onLoadVideo = (e) => {
    setVideoDuration(e.currentTarget.duration);
  };

  const handleProgress = (e) => {
    setProgressBarTime(e.currentTarget.currentTime);
  };

  const onVideoPlay = () => {
    setVideoStatus('play');
    CandidateActions.setAutoPlay();
  };

  const onVideoEnded = () => {
    setVideoStatus('pause');
  };

  const handlePlayVideo = () => {
    setVideoStatus('play');
    videoRef.current.play();
  };

  const handlePauseVideo = () => {
    setVideoStatus('pause');
    videoRef.current.pause();
  };

  const handleQuestionChange = (index) => {
    setSelectedQuestionIndex(index);
    setCurrentQuestionText(videos[index].text);
    setVideoUrl(videos[index].video.signedUrl);
    setVideoStatus('pause');
  };

  const handleMetaData = (e) => {
    if (e.currentTarget.duration === Infinity) {
      e.currentTarget.currentTime = 1e101;
      setVideoDuration(e.currentTarget.duration);
      e.currentTarget.onTimeUpdate = function () {
        e.currentTarget.currentTime = 0;
      };
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${candidateIntroProfileLink}`);
    successToast('Link copied successfully.');
  };

  const renderPersonalInfo = () => (
    <div className={styles.personalInfo}>
      <div className={styles.candidateEmailAndPhoneNoSection}>
        <div className={styles.candidateEmailDetails}>
          <div>
            <span><EmailIcon width="24" height="25" /></span>
            <span className={styles.candidateEmailTxt}>Email</span>
          </div>
          <div className={styles.candidateEmail}>
            {candidateEmail || '-'}
          </div>
        </div>
        <div className={styles.candidatePhoneNoDetails}>
          <div>
            <span><PhoneIcon width="24" height="25" /></span>
            <span className={styles.candidatePhoneTxt}>Phone</span>
          </div>
          <div className={styles.candidatePhoneNumber}>
            {candidatePhonenumber || '-'}
          </div>
        </div>
      </div>
      <div className={styles.sectionSeprator} />
      <div className={styles.candidateSummarySection}>
        <p className={styles.summaryHeading}>Summary</p>
        <p className={styles.summaryDescription}>
          {
            shortSummary ? (                     //eslint-disable-line
              <>
                {
                  candidateSummary && candidateSummary.length > 100 ? (
                    <>
                      {
                        `${candidateSummary.substring(0, 100)} ${toggle ? '' : '...'}`
                      }
                      {
                        toggle && (
                          candidateSummary.substring(100, candidateSummary.length)
                        )
                      }
                      <span type="button" role="button" tabIndex={0} onKeyDown={() => setToggle(!toggle)} onClick={() => setToggle(!toggle)} className={styles.readmoreText}>
                        {
                          toggle ? 'Read less' : 'Read more'
                        }
                      </span>
                    </>
                  ) : candidateSummary
                }
              </>
            ) : (
              candidateSummary || '-'
            )
          }
        </p>
      </div>
      <div className={styles.sectionSeprator} />
      <div className={styles.candidateSkillsSection}>
        <div className={styles.skillsHeading}>Top Skills</div>
        <div className={styles.candidateSkills}>
          {
            shortSkills ? (            //eslint-disable-line
              <>
                {
                  candidateResumeSkills && candidateResumeSkills.length > 6 ? (                       //eslint-disable-line
                    !moreToggle && candidateResumeSkills.map((skill, index) => (
                      index < 6 && <p className={styles.skill} key={Math.random()}>{skill}</p>
                    ))
                  ) : (
                    candidateResumeSkills.length !== 0 ? (
                      candidateResumeSkills.map(skill => (
                        <p className={styles.skill} key={Math.random()}>{skill}</p>
                      ))
                    ) : '-'
                  )
                }
                {
                  !moreToggle && (
                    <button className={styles.moretoggleOnSkills} type="button" onClick={() => setMoreToggle(!moreToggle)}>
                      {
                        moreToggle ? 'more +' : 'less -'
                      }
                    </button>
                  )
                }
                {
                  moreToggle && candidateResumeSkills.map(skill => (
                    <p className={styles.skill} key={Math.random()}>{skill}</p>
                  ))
                }
              </>
            ) : (
              <>
                {
                  candidateResumeSkills && candidateResumeSkills.length > 6 ? (                       //eslint-disable-line
                    !moreToggle && candidateResumeSkills.map((skill, index) => (
                      index < 6 && <p className={styles.skill} key={Math.random()}>{skill}</p>
                    ))
                  ) : (
                    candidateResumeSkills.length !== 0 ? (
                      candidateResumeSkills.map(skill => (
                        <p className={styles.skill} key={Math.random()}>{skill}</p>
                      ))
                    ) : '-'
                  )
                }
                {
                  candidateResumeSkills && candidateResumeSkills.length > 6 && (
                    <>
                      {
                        !moreToggle && (
                          <button className={styles.moretoggleOnSkills} type="button" onClick={() => setMoreToggle(!moreToggle)}>
                            more +
                          </button>
                        )
                      }
                      {
                        moreToggle && candidateResumeSkills.map(skill => (
                          <p className={styles.skill} key={Math.random()}>{skill}</p>
                        ))
                      }
                      {
                        moreToggle && (
                          <button className={styles.moretoggleOnSkills} type="button" onClick={() => setMoreToggle(!moreToggle)}>
                            less -
                          </button>
                        )
                      }
                    </>
                  )
                }
              </>
            )
          }
        </div>
      </div>
    </div>
  );

  const interestedCandidate = () => (
    <div className={styles.interestedCandidateContainer}>
      <div className={styles.sectionSeprator} />
      <div className={styles.interestedCandidateContent}>
        <span className={styles.interestedCandidateText}>
          Would you be interested in this candidate?
        </span>
        <button type="button" className={styles.messageCandidateBtn}>
          {`Message ${candidateName}`}
        </button>
      </div>
    </div>
  );

  const defaultState = () => (
    <div className={styles.defaultState}>
      <div className={styles.defaultStateContent}>
        <span><DefaultIcon /></span>
        <span className={styles.defaultStateTxt}>No information available.</span>
        <button className={styles.defaultStateVideoRecordBtn} onClick={handleDefaultIntroVideo} type="button">
          + Record your Intro Video
        </button>
      </div>
    </div>
  );

  const renderIntroVideo = () => (
    <div>
      {
        introductionInterview ? (
          <div>
            {/* Intro video question */}
            {
              fetchingCandidateAssessment ? (
                <div className={styles.loading}>
                  <ClipLoadingComponent />
                </div>
              )
                : (
                  <>
                    <p className={styles.introvideoQuestions}>{currentQuestionText}</p>
                    {/* Video Section */}
                    <div className={styles.candidateVideoContainer}>
                      <video                 //eslint-disable-line
                        ref={videoRef}
                        onDurationChange={e => onLoadVideo(e)}
                        preload="metadata"
                        controlsList="nodownload"
                        crossOrigin="anonymous"
                        onTimeUpdate={handleProgress}
                        onPlay={() => onVideoPlay()}
                        onEnded={onVideoEnded}
                        playsInline
                        className={styles.candidateVideo}
                        onLoadedMetadata={handleMetaData}
                        autoPlay
                        src={videoUrl}
                      >
                      </video>
                    </div>
                  </>
                )
            }

            {/* Progress bars with play and pause button */}
            <div className={styles.videoButtonContainer}>
              {
                videoStatus === 'pause' && (
                  <button className={styles.videoBtn} onClick={handlePlayVideo} type="button">
                    <PlayIcon />
                  </button>
                )
              }
              {
                videoStatus === 'play' && (
                  <button className={styles.videoBtn} onClick={handlePauseVideo} type="button">
                    <PauseIcon />
                  </button>
                )
              }
              {/* progress buttons */}
              <div className={styles.progressContainer}>
                {
                  videos && videos.map((x, index) => (
                    <progress                           //eslint-disable-line
                      value={
                        selectedQuestionIndex === index
                          ? progressBarTime
                          : 0
                      }
                      className={classnames(
                        styles.progressbar,
                        selectedQuestionIndex === index
                          ? styles.progressbarActive
                          : '',
                      )}
                      max={videoDuration}
                      type="button"
                      onKeyDown={() => handleQuestionChange(index)}
                      onClick={() => handleQuestionChange(index)}
                      key={Math.random()}
                    />
                  ))
                }
              </div>
            </div>
          </div>
        ) : (
          defaultState()
        )
      }
    </div>
  );

  const handleReadyForPurchase = () => {
    mxTrackEvent('Clicked on learn more', {
      candidateEmail,
    });
    history.push('/user/billing');
  };

  const renderScore = () => {
    if (!introductionInterview) {
      return defaultState();
    }

    if (isLockedDetails && !isCoaching) {
      return (
        <div>
          <img
            className={styles.lockedDetails}
            src={BLURED_SCORE_IMAGE}
            alt="candidate score"
          />
          <div className={styles.priceButtonContainer}>
            <button
              type="button"
              className={styles.learnMoreButton}
              onClick={handleReadyForPurchase}
            >
              Learn more
            </button>

          </div>
        </div>
      );
    }

    const {
      dressingScore, hairTidinessScore, bodyLanguageScore,
      emotionScore, arousalScore, sentimentScore,
      communicationScore, articulationScore,
    } = aiScores;
    const totalCommunicationScore = (communicationScore
      + articulationScore + sentimentScore) / 3;
    const {
      // attributes: professionalismAttributes,
      totalScore: professionalismScore,
    } = getAverageScore({ dressingScore, hairTidinessScore, bodyLanguageScore });
    const {
      // attributes: sociabilityAttributes,
      totalScore: sociabilityScore,
    } = getAverageScore({ emotionScore, arousalScore, sentimentScore });

    return (
      <div>
        {
          introductionInterview ? (
            <div>
              <p className={styles.workmapHeading}>
                Workmap Score
              </p>
              {
                workmapScores.length !== 0
                  ? (
                    <div className={styles.workmapSkillSection}>
                      {
                        workmapScores.map((skill, index) => (
                          <div className={styles.workmapSkill} key={Math.random()}>
                            <span className={styles.skillRating} style={{ border: `7px solid ${workmapColorCodes[index]}` }}>{skill.score}</span>
                            <span className={styles.workmapSkillName}>{skill.skill}</span>
                          </div>
                        ))
                      }
                    </div>
                  )
                  : (
                    <div style={{ margin: '1rem 0rem' }}>
                      -
                    </div>
                  )
              }
              <p className={styles.aivideoscoreTxt}>AI Video Score</p>
              {(totalCommunicationScore === 0
                && professionalismScore === 0
                && arousalScore === 0
                && sociabilityScore === 0)
                && <ScoreCircle score={0} />}
              {(totalCommunicationScore > 0
                || professionalismScore > 0
                || arousalScore > 0
                || sociabilityScore > 0)
                && (
                  <div className={styles.videoscoreContainer}>
                    <div className={styles.videoscoreSection}>
                      {/* Skill and percentage */}
                      <span className={styles.videoscoreContent}>
                        <span className={styles.videoscoreSkill}>Professionalism</span>
                        <span className={styles.videoscorePercent}>{`${professionalismScore.toFixed(2)}%`}</span>
                      </span>
                      {/* Percentage bar */}
                      <span>
                        <ProgressBar
                          now={professionalismScore}
                          className={styles.videoscoreProgressbar1}
                        />
                      </span>
                    </div>
                    <div className={styles.videoscoreSection}>
                      {/* Skill and percentage */}
                      <span className={styles.videoscoreContent}>
                        <span className={styles.videoscoreSkill}>Energy Levels</span>
                        <span className={styles.videoscorePercent}>{`${arousalScore.toFixed(2)}%`}</span>
                      </span>
                      {/* Percentage bar */}
                      <span>
                        <ProgressBar
                          now={arousalScore}
                          className={styles.videoscoreProgressbar2}
                        />
                      </span>
                    </div>
                    <div className={styles.videoscoreSection}>
                      {/* Skill and percentage */}
                      <span className={styles.videoscoreContent}>
                        <span className={styles.videoscoreSkill}>Communication</span>
                        <span className={styles.videoscorePercent}>{`${totalCommunicationScore.toFixed(2)}%`}</span>
                      </span>
                      {/* Percentage bar */}
                      <span>
                        <ProgressBar
                          now={totalCommunicationScore}
                          className={styles.videoscoreProgressbar3}
                        />
                      </span>
                    </div>
                    <div className={styles.videoscoreSection}>
                      {/* Skill and percentage */}
                      <span className={styles.videoscoreContent}>
                        <span className={styles.videoscoreSkill}>Sociability</span>
                        <span className={styles.videoscorePercent}>{`${sociabilityScore.toFixed(2)}%`}</span>
                      </span>
                      {/* Percentage bar */}
                      <span>
                        <ProgressBar
                          now={sociabilityScore}
                          className={styles.videoscoreProgressbar4}
                        />
                      </span>
                    </div>
                  </div>
                )}
            </div>
          ) : (
            defaultState()
          )
        }
      </div>
    );
  };

  const renderContent = () => {       //eslint-disable-line
    return (
      <div>
        {/* Sections with available options */}
        <div className={styles.candidateDetailsOptions}>
          <span className={selectedPersonalInfoOption ? styles.detailsOptionSelected : styles.detailsOption} type="button" role="button" tabIndex={0} onKeyDown={handlePersonalInfo} onClick={handlePersonalInfo}>Personal Info</span>
          <span className={selectedIntroVideoOption ? styles.detailsOptionSelected : styles.detailsOption} type="button" role="button" tabIndex={0} onKeyDown={handleIntroVideo} onClick={handleIntroVideo}>Intro Video</span>
          <span className={selectedScore ? styles.detailsOptionSelected : styles.detailsOption} type="button" role="button" tabIndex={0} onKeyDown={handleScore} onClick={handleScore}>Score</span>
        </div>
        {/* Conditionally render details according to the selected option */}
        <div className={styles.candidateDetails}>
          {
            selectedPersonalInfoOption && renderPersonalInfo()
          }
          {
            selectedIntroVideoOption && renderIntroVideo()
          }
          {
            selectedScore && renderScore()
          }
          {
            interestedCandidateSection && interestedCandidate()
          }
        </div>

        {/* Container with link to the candidates profile/portfolio */}
        {
          !interestedCandidateSection && !shortSummary && !shortSkills && (
            <div className={styles.candidateShareProfileContainer}>
              <p className={styles.candidateShareProfileTxt}>
                Easily share your profile instead
                of your résumé
              </p>
              {
                introductionInterview ? (
                  <div className={styles.shareProfileDiv}>
                    <span><ShareIcons /></span>
                    <button
                      className={styles.shareProfileButton}
                      onClick={handleCopyLink}
                      type="button"
                    >
                      <span><CopyIcon width="13" height="13" /></span>
                      <span className="ml-1">Copy Link</span>
                    </button>
                  </div>
                ) : (
                  <div className={styles.shareProfileDiv}>
                    <span><DisableShareIcons /></span>
                    <button
                      className={styles.shareProfileButton}
                      style={{ backgroundColor: '#CDD3E1', cursor: 'not-allowed' }}
                      type="button"
                    >
                      <span><CopyIcon width="13" height="13" /></span>
                      <span className="ml-1">Copy Link</span>
                    </button>
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    );
  };

  return (
    <div className={styles.personalInfoContainer}>
      {/* Candidate Details */}
      <div className={styles.nameAndImageSection}>
        {
          candidateThumbnail ? (
            <div
              style={{
                backgroundImage: `url(${candidateThumbnail})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                minWidth: '100px',
                minHeight: '100px',
                maxWidth: '100px',
                maxHeight: '100px',
                borderRadius: '25px',
                marginRight: '1rem',
                boxShadow: '0px 6px 44px rgba(185, 185, 218, .3)',
              }}
            />
          ) : (
            <div className={styles.ProfileLogo}>
              <DefaultLogo height={39} width={39} />
            </div>
          )
        }
        <div className="d-flex flex-column">
          <p className={styles.candidateName}>
            {candidateName || '-'}
          </p>
          <div className={styles.candidateLocation}>
            <span><LocationIcon width="18" height="18" /></span>
            <span className={`ml-1 ${styles.candidateCity}`}>
              {location || '-'}
            </span>
          </div>
          <div className={styles.candidateResumeAndLinkedin}>
            <div type="button">
              <a href={resume} className={!resume && styles.disable_cursor}>
                <ResumeIcon width="18" height="18" />
              </a>
            </div>
            <span type="button" className="ml-1">
              <a href={linkedlin} className={!linkedlin && styles.disable_cursor}><LinkedinIcon width="18" height="18" /></a>
            </span>
          </div>
        </div>
      </div>
      {
        renderContent()
      }
    </div>
  );
};

CandidatePersonalPersonalInfo.propTypes = {
  shortSummary: PropTypes.string,
  shortSkills: PropTypes.string,
  interestedCandidateSection: PropTypes.string,
  digitalprofile: PropTypes.shape({
    introductionInterviewUrl: PropTypes.string,
    resumeUrl: PropTypes.string,
    thumbnail: PropTypes.string,
    interviews: PropTypes.arrayOf(PropTypes.shape({})),
    esResponse: PropTypes.shape({
      resumeSkills: PropTypes.arrayOf(PropTypes.shape({})),
      workmapScores: PropTypes.arrayOf(PropTypes.shape({})),
      profile: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        location: PropTypes.string,
        phone: PropTypes.string,
        summary: PropTypes.string,
        linkedinURL: PropTypes.string,
      }),
    }),
  }),
};

CandidatePersonalPersonalInfo.defaultProps = {
  shortSummary: '',
  shortSkills: '',
  interestedCandidateSection: '',
  digitalprofile: {},
};

export default withTranslation()(withRouter(CandidatePersonalPersonalInfo));
