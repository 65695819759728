import React from 'react';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { HiCheckCircle, HiExclamationCircle } from 'react-icons/hi';

import 'react-toastify/dist/ReactToastify.css';
import '../css/Toast.scss';

const SuccessToast = ({ msg, actionLink, actionLinkText }) => (
  <Container>
    <Row>
      <Col xs={2}>
        <HiCheckCircle />
      </Col>
      <Col xs={10}>
        <span className="msg">{msg}</span>
        {
          actionLink && (
            <div>
              <a href={actionLink}>{actionLinkText}</a>
            </div>
          )
        }
      </Col>
    </Row>
  </Container>
);

const AlertToast = ({ msg, actionLink, actionLinkText }) => (
  <Container>
    <Row>
      <Col xs={2}>
        <HiExclamationCircle />
      </Col>
      <Col xs={10}>
        <span className="msg">{msg}</span>
        {
          actionLink && (
            <div>
              <a href={actionLink}>{actionLinkText}</a>
            </div>
          )
        }
      </Col>
    </Row>
  </Container>
);

SuccessToast.propTypes = {
  msg: PropTypes.string.isRequired,
  actionLink: PropTypes.string,
  actionLinkText: PropTypes.string,
};

SuccessToast.defaultProps = {
  actionLink: '',
  actionLinkText: '',
};


AlertToast.defaultProps = {
  actionLink: '',
  actionLinkText: '',
};

AlertToast.propTypes = {
  msg: PropTypes.string.isRequired,
  actionLink: PropTypes.string,
  actionLinkText: PropTypes.string,
};

export const successToast = (msg, action) => toast(
  <SuccessToast
    msg={msg}
    actionLink={action ? action.link : ''}
    actionLinkText={action ? action.text : ''}
  />, {
    className: 'successToast',
    bodyClassName: 'successToastBody',
    progressClassName: 'successToastProgress',
  },
);

export const alertToast = (msg, actionText, actionLink, props) => toast(
  <AlertToast msg={msg} actionLink={actionLink} actionLinkText={actionText} />, {
    className: 'alertToast',
    bodyClassName: 'alertToastBody',
    progressClassName: 'alertToastProgress',
    ...props,
  },
);
