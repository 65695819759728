import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from '../css/ErrorModal.module.scss';
import ErrorIcon from '../assets/Error.svg';

const ErrorModal = ({
  handleClose,
  show,
  errorText,
  subError = '',
}) => (
  <Modal
    show={show}
    onHide={handleClose}
  >
    <Modal.Header className={styles.header}>
      <img src={ErrorIcon} alt="test" className={styles.errorIcon} />
    </Modal.Header>
    <Modal.Body className={styles.body}>
      <h2>Oops!</h2>
      <p>{errorText}</p>
      <p>{subError || ''}</p>
      <Button className={styles.okButton} variant="outline-primary" onClick={handleClose}>
        Ok
      </Button>
    </Modal.Body>
  </Modal>
);

ErrorModal.defaultProps = {
  subError: '',
};

ErrorModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  errorText: PropTypes.string.isRequired,
  subError: PropTypes.string,
};

export default ErrorModal;
