import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../css/PriceButtonComponent.module.scss';
import { currentCountryUsingIp } from '../utils/utils';
import clientConfig from '../config/config';

const {
  CANDIDATE_PLANS,
} = clientConfig.default;

const PriceButtonComponent = () => {
  const [candidateLocation, setCandidateLocation] = useState(null);
  const [plan, setPlan] = useState(null);
  const history = useHistory();


  const redirectToBilling = () => {
    console.log('Redirecting to billing page...');
    history.push('/user/billing');
  };

  const handleSetPlanInfo = () => {
    if (candidateLocation === 'india') {
      setPlan(CANDIDATE_PLANS.basic.indianPlan);
    } else {
      setPlan(CANDIDATE_PLANS.basic.usPlan);
    }
  };

  useEffect(() => {
    handleSetPlanInfo();
    /**
     * Ingnored because of following reason due to urgency
     *  "React Hook useEffect has a missing dependency:"
     */
    // eslint-disable-next-line
  }, [candidateLocation]);


  useEffect(() => {
    (async function () {
      const currentCountry = await currentCountryUsingIp();
      if (currentCountry) {
        console.log('IFFFF', currentCountry);
        setCandidateLocation(currentCountry.toLowerCase());
      } else {
        setCandidateLocation('usa');
      }
    }());
  }, []);

  if (!plan || !candidateLocation) {
    return (<span>Loading</span>);
  }

  return (
    <button
      type="button"
      onClick={redirectToBilling}
      className={styles.priceButton}
    >
      {
        candidateLocation === 'india' ? <span>&#8377;</span> : <span>$</span>
      }
      {plan.amount}
      {' '}
      Only
    </button>
  );
};


export default PriceButtonComponent;
