import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '../css/CircularScoreChart.module.scss';

const CircularScoreChart = (props) => {
  const size = 65;
  const strokeWidth = 8;
  const {
    score,
  } = props;
  const center = size / 2;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * radius * Math.PI;
  const displayedScore = (score / 100 * 5).toFixed(1);

  const progressCircleColour = (num) => {
    if (num > 3) {
      return '#0BD9CA';
    }
    if (num >= 2) {
      return '#FFDF50';
    }
    return '#FB7A8E';
  };

  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const convertedScore = (score / 100) * 5;
    const progressBar = ((5 - convertedScore) / 5) * circumference;
    setOffset(progressBar);
  }, [score]); // eslint-disable-line

  return (
    <svg className={styles.svg} width={size} height={size}>
      <circle
        className={styles.baseCircle}
        cx={center}
        cy={center}
        r={radius + 4}
      />
      <circle
        className={styles.middleCircle}
        cx={center}
        cy={center}
        r={radius - 0.1}
      />
      <circle
        className={styles.progressCircle}
        stroke={progressCircleColour(displayedScore)}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        transform={`rotate(-90 ${center} ${center})`}
      />
      <text className={styles.scoreDisplay} x={center - 1} y={center + 9}>
        {displayedScore}
      </text>
    </svg>
  );
};
CircularScoreChart.propTypes = {
  score: PropTypes.number.isRequired,
};
export default CircularScoreChart;
