import alt from '../alt';
import Auth from '../auth/auth';


class CompanyActions {
  constructor() {
    this.setCompaniesDetails = data => data;
    this.setFetchingCompaniesDetails = data => data;
  }

  async getCompanyList(data) {
    this.setFetchingCompaniesDetails(true);
    try {
      const request = await fetch('/api/companylist', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
      });
      const response = await request.json();
      const { companyDetailsList, companyCount } = response;
      this.setCompaniesDetails({
        companyDetailsList,
        companyCount,
      });
    } catch (e) {
      console.log('Failed to retrive company list', e);
    } finally {
      this.setFetchingCompaniesDetails(false);
    }
  }
}


export default alt.createActions(CompanyActions);
