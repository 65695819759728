import React from 'react';
import PropTypes from 'prop-types';
import ActivityRings, { ActivityRingsConfig, ActivityRingsData } from 'react-activity-rings';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import styles from '../css/CandidateScore.module.scss';
import { getAverageScore } from '../utils/utils';
import { ProgressBar } from './VideoAssessmentComp';
import CandidateResumeInterface from './NewResumeComponent';

const RingComponent = (props) => {
  const {
    value,
    color,
  } = props;

  const activityData: ActivityRingsData = [ // eslint-disable-line
    {
      value,
      color,
    },
  ];
  const activityConfig: ActivityRingsConfig = { // eslint-disable-line
    width: 120,
    height: 120,
    radius: 50,
    ringSize: 12,
  };

  return (
    <div>
      <ActivityRings data={activityData} config={activityConfig} />
    </div>
  );
};

RingComponent.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

const Concentric = (props) => {
  const {
    overall,
    experience,
    skill,
    education,
  } = props.resume; //eslint-disable-line
  const activityData: ActivityRingsData = [ //eslint-disable-line
    {
      value: overall / 100,
      color: '#4FC1E9',
      label: 'Overall',
    },
    {
      value: education / 100,
      color: '#AC92EC',
      label: 'Education',
    },
    {
      value: skill / 100,
      color: '#FC6E51',
      label: 'Skills',
    },
    {
      value: experience / 100,
      color: '#EC87C0',
      label: 'Experience',
    },
  ];

  const activityConfig: ActivityRingsConfig = {  //eslint-disable-line
    width: 220,
    height: 220,
  }; // eslint-disable-line
  return (
    <div>
      <ActivityRings legend theme="light" data={activityData} config={activityConfig} />
    </div>
  );
};

Concentric.propTypes = {
  resume: PropTypes.shape.isRequired,
};

const CandidateScore = (props) => {
  const {
    aiScore,
    resume,
    workmapScore,
    resumeMandatory,
    workmapEnabled,
    totalCommunicationScore,
    resumeGenAiDetails,
  } = props;

  const {
    sentimentScore,
    arousalScore,
    emotionScore,
    bodyLanguageScore,
    hairTidinessScore,
    dressingScore,
  } = aiScore;


  const { t } = useTranslation();

  const sociabilityScore = getAverageScore({
    emotionScore,
    arousalScore,
    sentimentScore,
  }); //eslint-disable-line
  const professionalismScore = getAverageScore({
    dressingScore,
    hairTidinessScore,
    bodyLanguageScore,
  });

  const workmapColors = ['#4FC1E9', '#AC92EC', '#FC6E51', '#EC87C0', '#48CFAD', '#FFCE54', '#00B2B2', '#cb5f18', '#8A2BE2', '#0169D9', '#00FFFF', '#7B68EE'];
  return (
    <div className={styles.scoreContainer}>
      {
        resumeMandatory && (
          <div className={styles.resumeContainer}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <p className={styles.scoreHeadings}>Resume Score</p>
              <p className={styles.scoreHeadings}>
                {resume && `${resume.overall}%`}
              </p>
            </div>
            {resume === null
              ? <h3 className={styles.notAvailableText}>{t('candidateDetails.notAvailable')}</h3>
              : (
                <>
                  {
                    (resume.chatgpt_generated && resumeGenAiDetails)
                      ? (
                        <div>
                          <CandidateResumeInterface resumeContent={resumeGenAiDetails || {}} />
                        </div>
                      ) : (
                        <Concentric
                          resume={resume}
                        />
                      )
                  }
                </>
              )}
          </div>
        )
      }

      {
        workmapEnabled && (
          <div className={styles.scoreDiv}>
            <div>
              <p className={styles.scoreHeadings}>Workmap Score</p>
            </div>
            <div className={styles.workmapScoreDiv}>
              {workmapScore && workmapScore.length > 0 ? workmapScore.map((ring, index) => (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
                >
                  <div>
                    <div className={styles.ringContainer}>
                      <RingComponent
                        value={ring.score / 10}
                        color={workmapColors[index]}
                      />
                      <p className={styles.workmapScoreText}>{ring.score}</p>
                    </div>
                  </div>
                  <div className={styles.workmapText}>{ring.skill}</div>
                </div>
              )) : <h3 className={styles.notAvailableText}>{t('candidateDetails.notAvailable')}</h3>}
            </div>
          </div>
        )
      }

      <div className={styles.aiVideoScore}>
        <div>
          <p className={styles.scoreHeadings}>Video Score</p>
        </div>
        <div className={styles.aiVideoScoreSection}>
          <ProgressBar
            text={t('candidateDetails.professionalism')}
            value={Math.round(professionalismScore.totalScore)}
            progressBarStyle={styles.progressBarBlue}
            tooltip={`It is measured from the applicant’s behavior
                  (formal/informal) and looks at how the candidate
                  presents himself/herself in the video. It also measures
                  where eye contact is maintained during the video response, captures
                  if there is any fidgeting at all. Yes, dressing
                  formally would be advantageous.`}
            parsedCandidateAIScore={aiScore}
          />
        </div>

        <div className={styles.aiVideoScoreSection}>
          <ProgressBar
            text={t('candidateDetails.energyLevel')}
            value={Math.round(arousalScore)}
            progressBarStyle={styles.progressBarYellow}
            tooltip={`  Attitude, as the name suggests, looks for sentiments
                  along with a combination of energy and emotion. This
                  model looks at a combination of metrics from communication and
                  Sociability (for eg. confident, encouraged, positive)`}
            parsedCandidateAIScore={aiScore}
          />
        </div>

        <div className={styles.aiVideoScoreSection}>
          <ProgressBar
            text={t('candidateDetails.communication')}
            value={Math.round(totalCommunicationScore)}
            progressBarStyle={styles.progressBarLightRed}
            tooltip={` This is primarily measuring the applicant’s
              clarity of speech. It also looks at verbal communication
              fluency and modulation of speech. Speaking clearly and with the
              right pace would be advantageous here. Variation in spoken
              pitch at appropriate times throughout the video would be beneficial.`
            }
            parsedCandidateAIScore={aiScore}
          />
        </div>
        <div className={styles.aiVideoScoreSection}>
          <ProgressBar
            text={t('candidateDetails.sociability')}
            value={Math.round(sociabilityScore.totalScore)}
            progressBarStyle={styles.progressBarPink}
            tooltip={` This AI model is more focused on scoring applicant’s sentiments,
              using how the candidate communicates and shows positive interpersonal
              skills and tactfulness. It usually looks for positive sentiment
              words and energy levels of an applicant`
            }
            parsedCandidateAIScore={aiScore}
          />
        </div>
      </div>

    </div>
  );
};

CandidateScore.propTypes = {
  aiScore: PropTypes.shape.isRequired,
  workmapScore: PropTypes.shape.isRequired,
  resume: PropTypes.shape.isRequired,
  resumeGenAiDetails: PropTypes.shape.isRequired,
  resumeMandatory: PropTypes.bool.isRequired,
  workmapEnabled: PropTypes.bool.isRequired,
  totalCommunicationScore: PropTypes.number.isRequired,
};

export default withTranslation()(withRouter(CandidateScore));
