// @flow
import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import '../css/pagination.scss';
import CompanyActions from '../actions/CompanyActions';

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}: {
  onPageChange: func,
  totalCount: number,
  siblingCount: number,
  pageSize: number,
  className: string,
  currentPage: number,
}) => {    //eslint-disable-line

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const handleClickOnNumber = async (pageNumber) => {
    const numberDataSend = {
      startIndex: pageNumber * pageSize - pageSize + 1,
      endIndex: pageNumber * pageSize,
    };
    await CompanyActions.getCompanyList(numberDataSend);
    onPageChange(pageNumber);
  };

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = async () => {
    const numberDataSend = {
      startIndex: (currentPage * pageSize - pageSize + 1) + pageSize,
      endIndex: (currentPage * pageSize) + pageSize,
    };
    await CompanyActions.getCompanyList(numberDataSend);
    onPageChange(currentPage + 1);
  };

  const onPrevious = async () => {
    const numberDataSend = {
      startIndex: (currentPage * pageSize - pageSize + 1) - pageSize,
      endIndex: (currentPage * pageSize) - pageSize,
    };
    await CompanyActions.getCompanyList(numberDataSend);
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('paginationContainer', { [className]: className })}
    >
      <li                                                         //eslint-disable-line
        className={classnames('paginationItem', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map(pageNumber => {     //eslint-disable-line
        if (pageNumber === DOTS) {
          return <li className="dots">&#8230;</li>;
        }

        return (
          <li                                                    //eslint-disable-line
            className={classnames('paginationItem', {
              selected: pageNumber === currentPage,
            })}
            onClick={() => handleClickOnNumber(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li                                                      //eslint-disable-line
        className={classnames('paginationItem', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
