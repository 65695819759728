import React from 'react';
import { BeatLoader, ClipLoader } from 'react-spinners';
import { css } from 'react-emotion';
import PropTypes from 'prop-types';

const ClipLoadingComponent = ({ inverse, type }) => {
  if (type === 'beat') {
    return (
      <BeatLoader color="#6196fe" size={20} className={css`float: right; margin-left: 5px`} />
    );
  }
  return (
    <ClipLoader color={inverse ? '#ffffff' : '#fd6144'} size={20} className={css`float: right; margin-left: 5px`} />
  );
};

ClipLoadingComponent.propTypes = {
  inverse: PropTypes.bool,
  type: PropTypes.string,
};

ClipLoadingComponent.defaultProps = {
  inverse: false,
  type: 'clip',
};

export default ClipLoadingComponent;
