import React, { useState, useEffect, useRef } from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ReactComponent as PlayIcon } from '../assets/PlayIcon.svg';
import { ReactComponent as PauseIcon } from '../assets/PauseIcon.svg';
import styles from '../css/CandidateResponse.module.scss';
import CandidateActions from '../actions/CandidateActions';
import CandidateStore from '../stores/CandidateStore';
import { isMobile } from '../utils/utils';

const CandidateResponse = (props) => {
  const {
    interviewId,
    candidateId,
  } = props;

  const videoRef = useRef(null);
  const [toggleState, setToggleState] = useState(true);
  const [videos, setVideos] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [active, setActive] = useState();
  const [candidateData, setCandidateData] = useState(); //eslint-disable-line
  const [caption, setCaption] = useState([]);
  const [captionIndex, setCaptionIndex] = useState(0);
  const [transcriptQuestion, setTranscriptQuestion] = useState(0);
  const [videoPlayStatus, setVideoPlayStatus] = useState('pause');
  // const [playerIcon, setPlayerIcon] = useState(play);
  const [videoDuration, setVideoDuration] = useState(0);
  const [progressBarTime, setProgressBarTime] = useState();
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const [isAutoPlay, setIsAutoPlay] = useState(false);
  const { t } = useTranslation();


  const handleStoreChange = (store) => {
    const {
      candidateAssessmentsError,
      candidateAssessments,
      isFetchingCandidateAssessment,
    } = store;
    if (candidateAssessments[candidateId]
      && !isFetchingCandidateAssessment
      && !candidateAssessmentsError) {
      const candidate = candidateAssessments[candidateId];
      setCandidateData(candidate);
      setVideos(candidate.videos);
      setVideoUrl(candidate.videos[0].video.signedUrl);
      setActive(candidate.videos[0].id);
      setTranscriptQuestion(candidate.videos[0].text);
      setCaption(candidate.scores.aiScores.caption);
    }
  };

  const truncate = (str, n) => { // eslint-disable-line
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
  };

  useEffect(() => {
    CandidateStore.listen(handleStoreChange);
    const store = CandidateStore.getState();
    if (store.candidateAssessments[candidateId]) {
      handleStoreChange(store);
    } else {
      CandidateActions.fetchCandidateAssessment(candidateId, interviewId);
    }

    const handleResize = () => setIsMobileView(isMobile());
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      CandidateStore.unlisten(handleStoreChange);
    };

    // eslint-disable-next-line
  }, []); // eslint-disable-next-line

  const handleToggle = () => {
    setToggleState(!toggleState);
  };

  const handleQuestionChange = (video, index) => {
    setVideoUrl(video.video.signedUrl);
    setActive(video.id);
    setCaptionIndex(index);
    setTranscriptQuestion(video.text);
    setIsAutoPlay(true);
  };

  const handlePlay = () => {
    videoRef.current.play();
    setVideoPlayStatus('play');
  };

  const handlePause = () => {
    videoRef.current.pause();
    setVideoPlayStatus('pause');
  };

  const onLoadVideo = (e) => {
    setVideoDuration(e.currentTarget.duration);
  };

  const onVideoPlay = async () => {
    setVideoPlayStatus('play');
  };

  const onVideoEnded = async () => {
    setVideoPlayStatus('pause');
  };

  const handleProgress = (e) => {
    setProgressBarTime(e.currentTarget.currentTime);
  };

  const handleMetaData = (e) => {
    if (e.currentTarget.duration === Infinity) {
      e.currentTarget.currentTime = 1e101;
      setVideoDuration(e.currentTarget.duration);
      e.currentTarget.onTimeUpdate = function () { //eslint-disable-line
        this.ontimeupdate = () => { //eslint-disable-line
          return; // eslint-disable-line
        };
        e.currentTarget.currentTime = 0;
        return; //eslint-disable-line
      };
    }
  };

  if (!candidateData) {
    return <div>Data loading</div>;
  }

  return (
    isMobileView ? (
      <div className={styles.mobileViewContainer}>
        <div className={styles.mobileQuestionHeading}>
          <p>Question</p>
        </div>
        <div className={styles.mobileButtonContainer}>

          {
            videos.map(
              (x, index) => (
                <button
                  type="button"
                  onClick={() => handleQuestionChange(x, index)}
                  className={active === x.id ? styles.activeButton : styles.inActiveButton}
                >
                  <span>
                    {console.log('active === x.id', active === x.ide)}

                    {index}
                  </span>
                </button>
              ),
            )}


        </div>
        <div className={styles.questionTextContainer}>
          <p>What is your company in 2-5 words ?</p>
        </div>
        <div className={styles.videoPlayerContainer}>
          { /* eslint-disable jsx-a11y/media-has-caption */}
          <video
            ref={videoRef}
            src={videoUrl}
            preload="metadata"
            crossOrigin="anonymous"
            className={styles.videoStyle}
            onPlay={onVideoPlay}
            onEnded={onVideoEnded}
            onLoadedMetadata={(e) => { handleMetaData(e); }}
            autoPlay={isAutoPlay}
            onDurationChange={onLoadVideo}
            disablePictureInPicture
            onTimeUpdate={handleProgress}
            playsInline
            controls={false}
          />
        </div>

        <div className={styles.videoTranscriptHeading}>
          <div className={styles.playerControlContainer}>
            <div>

              {
                toggleState && videoPlayStatus === 'pause' && (
                  <button
                    type="button"
                    className={`${styles.candidateDetailPlayButton}`}
                    onClick={handlePlay}
                    disabled={videos.length === 0}
                  >
                    <PlayIcon />
                  </button>
                )
              }

              {toggleState && videoPlayStatus === 'play' && (
                <button
                  type="button"
                  className={`${styles.candidateDetailPlayButton}`}
                  onClick={handlePause}
                >
                  <PauseIcon />
                </button>
              )}
            </div>
            <div className={styles.progressBarContainer}>
              {toggleState && videos.map(
                (x, index) => (
                  <progress  // eslint-disable-line
                    value={ // eslint-disable-line
                      active === x.id
                        ? progressBarTime  // eslint-disable-line
                        : 0 // eslint-disable-line
                    }
                    onClick={() => handleQuestionChange(x, index)}
                    className={classnames(
                      styles.progressBar,
                      active === index
                        ? styles.progressBarActive
                        : '',
                    )}
                    max={videoDuration}
                    key={x.id}
                  />

                ),
              )}
            </div>
          </div>
          <p>Video Transcript</p>
          <p className={styles.transcriptAnswer}>{caption[captionIndex]}</p>
        </div>
      </div>
    ) : (
      <div className={styles.responseRoot}>
        {/* <span className={styles.questionHeading}>{t('candidateDetails.questions')}</span> */}
        <div className={styles.videoSection}>
          <div className={styles.video_questions}>
            {videos
              && videos.map((question, index) => (
                <div // eslint-disable-line
                  title={question.questiontext} // eslint-disable-line
                  className={styles.questionContainer}
                  onClick={() => handleQuestionChange(question, index)}
                >
                  <div
                    className={classnames(
                      styles.circle,
                      styles.fullHeight,
                      active === question.id
                        ? styles.circleActive
                        : '',
                    )}
                  >
                    <a className={active === question.id ? styles.activeLink : styles.inActiveLink}>  {/* eslint-disable-line */}
                      {' '}  {/*  eslint-disable-line */}
                      {index + 1}
                    </a>
                  </div>
                  <span
                    className={classnames(
                      styles.question,
                      active === question.id
                        ? styles.questionActive
                        : '',
                    )}
                  >
                    {truncate(question.text, 83)}
                  </span>
                </div>
              ))}
          </div>
          <div className={styles.videoContainer}>
            <video /* eslint-disable jsx-a11y/media-has-caption */
              ref={videoRef}
              src={videoUrl}
              crossOrigin="anonymous"
              className={styles.videoStyle}
              onPlay={onVideoPlay}
              onEnded={onVideoEnded}
              onLoadedMetadata={(e) => { handleMetaData(e); }}
              autoPlay={isAutoPlay}
              onDurationChange={onLoadVideo}
              disablePictureInPicture
              onTimeUpdate={handleProgress}
              playsInline
              controls={false}
            />
          </div>


          <div className={styles.transcript_parent}>
            <div className={styles.transcript_child}>
              <div className={styles.videoTranscriptHeadingDesktop}>
                {toggleState && <span>{t('candidateDetails.videoTranscript')}</span>}
                {!toggleState && <span>{t('candidateDetails.candidateSummary')}</span>}
                <div className={styles.switchContainer}>
                  <Switch
                    onColor="#6196FE"
                    checkedIcon=""
                    uncheckedIcon=""
                    checked={toggleState}
                    height={18}
                    width={30}
                    onChange={handleToggle}
                  />
                </div>
              </div>
              <div className={styles.playerControlContainer}>
                <div>

                  {
                    toggleState && videoPlayStatus === 'pause' && (
                      <button
                        type="button"
                        className={`${styles.candidateDetailPlayButton}`}
                        onClick={handlePlay}
                        disabled={videos.length === 0}
                      >
                        <PlayIcon />
                      </button>
                    )
                  }

                  {toggleState && videoPlayStatus === 'play' && (
                    <button
                      type="button"
                      className={`${styles.candidateDetailPlayButton}`}
                      onClick={handlePause}
                    >
                      <PauseIcon />
                    </button>
                  )}
                </div>
                <div className={styles.progressBarContainer}>
                  {toggleState && videos.map(
                    (x, index) => (
                      <progress  // eslint-disable-line
                        value={ // eslint-disable-line
                          active === x.id
                            ? progressBarTime  // eslint-disable-line
                            : 0 // eslint-disable-line
                        }
                        onClick={() => handleQuestionChange(x, index)}
                        className={classnames(
                          styles.progressBar,
                          active === index
                            ? styles.progressBarActive
                            : '',
                        )}
                        max={videoDuration}
                        key={x.id}
                      />

                    ),
                  )}
                </div>
              </div>
            </div>
            <div className={styles.video_transcript}>
              <p className="f16-normal-grey">
                {toggleState && videos && videos[active]
                  ? videos[active].questiontext
                  : ''}
              </p>
              {toggleState && (
                <span className="f16-400-bold-dark">
                  <p className={styles.transcriptQuestion}>{transcriptQuestion}</p>
                  <p className={styles.transcriptAnswer}>{caption[captionIndex]}</p>
                </span>
              )}
              {!toggleState && (
                <span className="f16-400-bold-dark">
                  {candidateData.profileSummary || candidateData.candidateIaiSummary}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

CandidateResponse.propTypes = {
  interviewId: PropTypes.string.isRequired,
  candidateId: PropTypes.string.isRequired,
};

export default withTranslation()(withRouter(CandidateResponse));
