import alt from '../alt';
import Auth from '../auth/auth';
import { alertToast, successToast } from '../components/Toast';


class CandidateActions {
  constructor() {
    this.fetchDigitalProfileStart = () => true;
    this.fetchDigitalProfileSuccess = data => data;
    this.fetchDigitalProfileFailure = err => err;
    this.fetchDigitalProfileNotFound = () => true;

    this.fetchCandidateAssessmentStart = () => true;
    this.fetchCandidateAssessmentSuccess = (candidateId, data) => ({ candidateId, data });
    this.fetchCandidateAssessmentFailure = err => err;
    this.fetchOrganizationId = orgId => orgId;
    // -need to check why following line is written
    this.fetchCoachingFlag = () => true;
    this.setResumeValidFlag = data => data;
    this.fetchCandidateSubscriptionSuccess = data => data;
    this.fetchCandidateSubscriptionFailure = () => true;
    this.setPaymentUnderProcess = flag => flag;
    this.setAutoPlay = () => true;
    this.setAppDrawerOpen = data => data;
    this.setCoachingStatus = data => data;
    this.setVideoInterviewGatePrompt = value => value;
    this.setVideoInterviewGatePromptConditionalFlag = value => value;
    this.setIsCandidateProfileDeleted = () => true;
    this.setDeletingCandidateProfile = value => value;
    this.setIsNavigationDrawerOpen = value => value;
    this.isReportDownloading = flag => flag;
    this.loadingFirstName = flag => flag;
    this.setExistingCandidate = data => data;
    this.fetchMockInterviewSuccess = data => data;
    this.fetchMockInterviewCompanyData = data => data;
    this.fetchMockInterviewSkillsSuccess = data => data;
    this.setFetchingMockInterview = value => value;
  }

  async fetchDigitalProfile() {
    this.fetchDigitalProfileStart();
    try {
      const res = await fetch(`/api/interviewee/profile/${Auth.getProfileInfo('email')}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
      });
      const json = await res.json();
      if (res.status === 200) {
        this.fetchDigitalProfileSuccess(json);
      } else if (res.status === 404) {
        this.fetchDigitalProfileNotFound();
      } else if (res.status === 500) {
        console.error(`Error: ${json.error}`);
        this.fetchDigitalProfileFailure(json.error);
      } else if (res.status === 401) {
        console.error(`Error: ${json.error}`);
        Auth.signOut();
      }
    } catch (err) {
      console.error(`Error: ${err.message}`);
      this.fetchDigitalProfileFailure(err.message);
    }
  }

  async fetchCandidateAssessment(candidateId, interviewId) {
    this.fetchCandidateAssessmentStart();
    try {
      const req = await fetch(`/api/interviewee/assessment/${interviewId}/${candidateId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
      });
      const res = await req.json();
      if (req.status >= 200 && req.status <= 300) {
        this.fetchCandidateAssessmentSuccess(candidateId, res);
      } else {
        throw new Error(res.error);
      }
    } catch (err) {
      console.error(err.message);
      this.fetchCandidateAssessmentFailure(err.message);
    }
  }

  async fetchSubscription() {
    try {
      const req = await fetch(`/api/subscription/${Auth.getProfileInfo('email')}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
      });
      const res = await req.json();
      if (req.status >= 200 && req.status <= 300) {
        this.fetchCandidateSubscriptionSuccess(res.subscription[0]);
      } else {
        this.fetchCandidateSubscriptionFailure();
        throw new Error(res.error);
      }
    } catch (e) {
      console.log('Error :', e);
      this.fetchCandidateSubscriptionFailure();
    }
  }


  async paymentProcess(data) {
    try {
      this.setPaymentUnderProcess(true);
      const req = await fetch('/api/subscription/payment', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const paymentResponse = await req.json();
      if (paymentResponse.success) {
        this.setPaymentUnderProcess(false);
        return true;
      }
      this.setPaymentUnderProcess(false);
      throw new Error(req.error);
    } catch (e) {
      console.log('Error :', e);
      return false;
    }
  }

  async deleteCandidate(email) {
    try {
      this.setDeletingCandidateProfile(true);
      const req = await fetch(`/api/settings/candidate/${email}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'content-type': 'application/json',
        },
      });
      if (req.status >= 200 && req.status <= 300) {
        successToast('Profile deleted successfully');
        this.setIsCandidateProfileDeleted();
        this.setDeletingCandidateProfile(false);
        Auth.signOut();
        return true;
      }
      alertToast('Failed to delete profile');
      this.setDeletingCandidateProfile(false);
      throw new Error(req.error);
    } catch (e) {
      console.log('Error :', e);
      this.setDeletingCandidateProfile();
      return false;
    }
  }

  async updateIsLocked(isLocked) {
    try {
      const req = await fetch(`/api/subscription/expireSubscription/${Auth.getProfileInfo('email')}/${isLocked}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
      });
      const res = await req.json();
      if (req.status >= 200 && req.status <= 300) {
        this.fetchSubscription();
      } else {
        throw new Error(res.error);
      }
    } catch (e) {
      console.log('Updating account lock failed');
      // log needs to be added.
    }
    // /expireSubscription/:candidateEmail/:isLocked
  }

  async fetchCandidateCoachingStatus() {
    try {
      const req = await fetch(`/api/getCoachingStatus/${Auth.getProfileInfo('email')}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
      });
      const res = await req.json();
      if (req.status >= 200 && req.status <= 300) {
        this.setCoachingStatus(res.message);
      } else {
        this.setCoachingStatus(false);
        throw new Error(res.error);
      }
    } catch (e) {
      console.log('Updating account lock failed');
      // log needs to be added.
    }
  }

  async downloadCandidateReport(interviewId, candidateId, interviewName) {
    this.isReportDownloading(true);
    try {
      fetch(`/api/interviewee/downloadReport/${interviewId}/${candidateId}`)
        .then(response => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${interviewName}.pdf`;
          a.click();
          URL.revokeObjectURL(url);
          this.isReportDownloading(false);
        });
    } catch (e) {
      this.isReportDownloading(false);
      console.log('Error while downloading candidate report');
    }
  }

  async checkAndFetchExistingCandidate(email) {
    try {
      this.loadingFirstName(true);
      const req = await fetch(`/api/checkExistingCandidate?email=${email}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
      });
      const res = await req.json();
      if (req.status >= 200 && req.status <= 300) {
        const {
          isNameEditable,
          name,
        } = res;
        this.loadingFirstName(false);
        this.setExistingCandidate({
          isNameEditable,
          name,
        });
      } else {
        this.loadingFirstName(false);
        throw new Error(res.error);
      }
    } catch (e) {
      this.loadingFirstName(false);
      console.log('Updating account lock failed');
    }
  }


  async generateMockInterview(orgId, data) {  //eslint-disable-line
    console.log(' Auth.getIdToken() ', Auth.getIdToken());

    try {
      const request = await fetch(`/api/interviews/create-mock-interview/${orgId}`, {
        headers: {
          // Authorization: `Bearer ${Auth.getIdToken()}`,
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
      });
      const response = await request.json();
      const {
        url,
      } = response;
      return url;
    } catch (e) {
      console.log('Failed to retrive company list', e);
    }
  }

  async fetchMockInterviewOnSearch(orgId, searchTerm) {
    this.setFetchingMockInterview(true);
    try {
      const req = await fetch(`/api/interviews/get-mock-interview/${orgId}/${searchTerm}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
        method: 'GET',
      });

      if (req.status === 200) {
        const res = await req.json();
        const { interviewList } = res;
        this.fetchMockInterviewSuccess(interviewList);
      } else {
        // this.handleFetchLocationError();
        throw Error(req.statusText);
      }
    } catch (e) {
      console.log('Error:', e);
    } finally {
      this.setFetchingMockInterview(false);
    }
  }

  async fetchComapnyInformationUsingOrgId(orgId) {
    try {
      const req = await fetch(`/api/interviews/get-company-information/${orgId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
        method: 'GET',
      });

      if (req.status === 200) {
        const res = await req.json();
        console.log('>>>>> RES <<<<', res);
        const {
          message,
        } = res;
        console.log('>>>>> message <<<<', message);
        this.fetchMockInterviewCompanyData(message);
      } else {
        throw Error(req.statusText);
      }
    } catch (e) {
      console.log('Error:', e);
    }
  }

  async fetchMockInterviewSkillsOnSearch(searchTerm) {
    try {
      const req = await fetch(`/api/interviews/get-mock-interview-skills/${searchTerm}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
        method: 'GET',
      });

      if (req.status === 200) {
        const res = await req.json();
        const { skills } = res;
        this.fetchMockInterviewSkillsSuccess(skills);
      } else {
        // this.handleFetchLocationError();
        throw Error(req.statusText);
      }
    } catch (e) {
      console.log('Error:', e);
    }
  }

  async fetchOrgCoachingStatus(interviewid) {     //eslint-disable-line
    try {
      const req = await fetch(`/api/coachingStatus/${interviewid}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
      });
      const res = await req.json();
      if (req.status >= 200 && req.status <= 300) {
        return res.message;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async updateBrowserToggleEvent(candidateId, pastedAns) {       //eslint-disable-line
    try {
      fetch(`/api/pastedAnswer/${candidateId}/${pastedAns}`, {
        method: 'PATCH',
      });
    } catch (e) {
      console.log(e);
    }
  }
}


export default alt.createActions(CandidateActions);
