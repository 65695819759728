import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Navbar,
  Nav,
  NavDropdown,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
// import { ReactComponent as NotificationBell } from '../assets/NotificationBell.svg';
import { ReactComponent as Underline } from '../assets/UnderLine.svg';
// import { ReactComponent as LocationImg } from '../assets/locationCompanydetails.svg';
import { ReactComponent as NextIcon } from '../assets/NextVector.svg';
import { ReactComponent as LongArrow } from '../assets/LongArrow.svg';
import {
  META_TITLE_COMPANY_HIRING,
  META_DESCRIPTION_COMPANY_HIRING,
} from '../utils/constants';
import bannerImg from '../assets/BannerImgHiring.png';
import CompanyActions from '../actions/CompanyActions';
import CompanyStore from '../stores/CompanyStore';
import Pagination from './Pagination';
// import JobHelmet from '../components/JobHelmet';
import ClipLoadingComponent from '../components/ClipLoadingComponent';
import { ReactComponent as ErrorIcon } from '../assets/ApplicationFormErrorIcon.svg';
import clientConfig from '../config/config';
import styles from '../css/CompaniesHiringPage.module.scss';

const CompaniesHiringPage = () => {          //eslint-disable-line

  const [currentPage, setCurrentPage] = useState(1);
  const [companylist, setCompanylist] = useState([]);
  const [totalCompanies, setTotalCompanies] = useState(null);
  const [fetchingData, setFetchingData] = useState(true);

  const history = useHistory();

  const PageSize = 9;

  const handleCompanyStoreData = (data) => {
    setCompanylist(data.companiesDetails.companyDetailsList);
    setTotalCompanies(data.companiesDetails.companyCount.total_counts);
    setFetchingData(data.fetchingCompaniesDetails);
  };

  useEffect(() => {
    const sevdata = {
      startIndex: 1,
      endIndex: 9,
    };
    CompanyActions.getCompanyList(sevdata);
    CompanyStore.listen(handleCompanyStoreData);
  }, []);

  const handleCareerPageClick = (routeLink) => {
    history.push(`/${routeLink}`);
  };

  const MetaTags = ({ location }: { location: Object }) => {   //eslint-disable-line
    return (
      <Helmet>
        <title>{META_TITLE_COMPANY_HIRING}</title>
        <meta name="keywords" content="jobs, career" />
        <meta property="og:url" content={`${location.protocol}//${location.host}${location.pathname}`} />
        <meta property="og:title" content={META_TITLE_COMPANY_HIRING} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={META_DESCRIPTION_COMPANY_HIRING} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={META_TITLE_COMPANY_HIRING} />
        <meta name="twitter:description" content={META_DESCRIPTION_COMPANY_HIRING} />
      </Helmet>
    );
  };

  const renderClientLogo = (img, isDesktop) => (
    <div style={{
      backgroundColor: '#F8F9FB',
      borderRadius: isDesktop ? '10px' : '5px',
      padding: isDesktop ? '' : '0rem .1rem',
    }}
    >
      <div
        style={{
          backgroundImage: `url(${img})`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          minWidth: isDesktop ? '90px' : '60px',
          minHeight: isDesktop ? '90px' : '60px',
          maxWidth: isDesktop ? '90px' : '60px',
          maxHeight: isDesktop ? '90px' : '60px',
          borderRadius: isDesktop ? '23px' : '5px',
        }}
      />
    </div>
  );

  const EmptyState = () => (
    <div className={styles.emptyStateContainer}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ErrorIcon />
      </div>
      <div className={styles.emptyStateText}>
        No data found
      </div>
    </div>
  );

  const NavigationBar = () => (
    <div className={styles.navContainer}>
      <Navbar collapseOnSelect expand="lg" bg="white">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <div className={styles.navItems}>
              {/* clientConfig.default.YESBANK_ORGANIZATION_ID */}
              <Nav.Link
                href={clientConfig.default.INTERVIEWERAI_DOMAIN}
                className={styles.navItem}
              >
                Home
              </Nav.Link>
              <NavDropdown title="Product" className={styles.navItem}>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}business/`}
                >
                  For Business
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}universities/`}
                >
                  For Universities
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}candidates/`}
                >
                  For Candidates
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}success-stories/`}
                >
                  Success Stories
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                className={styles.navItem}
                href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}pricing/`}
              >
                Pricing
              </Nav.Link>
              <NavDropdown
                title="Resources"
                id="collasible-nav-dropdown"
                className={styles.navItem}
              >
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}how-it-works/`}
                >
                  How it works
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}explainable-ai/`}
                >
                  The Science
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}structured-interviews/`}
                >
                  Structured Interviews
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}help-center/`}
                >
                  Help Center
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}faq/`}
                >
                  FAQs
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}whitepapers/`}
                >
                  White Papers
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_RESUME_BUILDER_DOMAIN}`}
                >
                  Resume Builder
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Company"
                id="collasible-nav-dropdown"
                className={styles.navItem}
              >
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}about/`}
                >
                  About Us
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}newsroom/`}
                >
                  Newsroom
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}about/#team`}
                >
                  Team
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}blogs/`}
                >
                  Blogs
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.CAREER_SIDE_INTERVIEWERAI_DOMAIN}interviewer-ai-sg`}
                >
                  Careers
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}contact/`}
                >
                  Contact Us
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={styles.navItem}
                  href={`${clientConfig.default.INTERVIEWERAI_DOMAIN}schedule-demo/`}
                >
                  Schedule a Demo
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown.Divider />
            </div>
          </Nav>
          <Nav>
            <div className={styles.navigationButtons}>
              <a
                className={styles.signupButton}
                style={{
                  textDecoration: 'none',
                  color: 'white',
                }}
                href="/user/login/"
              >
                Sign up / Login
              </a>
              {/* <div>
                  <DropdownButton
                    title="EN"
                    variant="outline-secondary"
                    id="input-group-dropdown-1"
                    className={styles.dropDownButton}
                  >
                    <Dropdown.Item href="#">Action</Dropdown.Item>
                    <Dropdown.Item href="#">Another action</Dropdown.Item>
                    <Dropdown.Item href="#">Something else here</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#">Separated link</Dropdown.Item>
                  </DropdownButton>
                </div> */}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );

  const TopSectionContent = () => (
    <div className={styles.contentContainer}>
      {/* Hero heading */}
      <div className={styles.center}>
        <div className={styles.textContainer}>
          <div className={styles.companyHiringText}>
            Companies Hiring
          </div>
          <div>
            <Underline />
          </div>
        </div>
      </div>
      {/* Hero text */}
      <div className={styles.center}>
        <div className={styles.heroText}>
          Get access to more dream jobs and opportunities.
          More companies are hiring for key roles using our AI-based video platform.
          Start browsing here.
        </div>
      </div>
      {/* Search bar */}
      {/* <div className={styles.center}>
          <div className={styles.searchboxContainer}>
            <form className={styles.searchBox}>
              <input
                type="text"
                placeholder="Search company"
                name="search"
                className={styles.inputSearch}
              />
            </form>
            <button
              type="submit"
              className={styles.searchButton}
            >
              Search
            </button>
          </div>
        </div> */}
    </div>
  );

  const Card = ({ job }: { job: Object }) => {                           //eslint-disable-line
    return (
      <div className={styles.cardContainer}>
        {/* Card Header(company logo and name/location) */}
        <div className={styles.cardHeader}>
          {/* Logo */}
          <div className={styles.companyLogo}>
            {
              renderClientLogo(job.company_logo_url, true)
            }
          </div>
          {/* Name and Location */}
          <div className={styles.companynameAndLocation}>
            <div className={styles.companyName}>
              {`${job.company_name}`}
            </div>
            {/* <div className={styles.companyLocation}>
              <span className={styles.centerVerticalHorizontal}>
                <LocationImg stroke="#5F6981" />
              </span>
              <span className={styles.location}>
                Washington
              </span>
            </div> */}
          </div>
        </div>
        <hr style={{ color: '#F8F9FB' }} />
        {/* Active job pannel */}
        <div className={styles.activeJobContainer}>
          <div className={styles.activeJobsSection}>
            <div className={styles.activeJobText}>
              Active Jobs
            </div>
            <div className={styles.activejob}>
              {`${job.active_jobs} Active`}
            </div>
          </div>
          <div
            className={styles.activeJobMoreIcon}
            onClick={() => handleCareerPageClick(job.career_site_company_name)}
            onKeyDown={() => handleCareerPageClick(job.career_site_company_name)}
            tabIndex={0}
            role="button"
          >
            <NextIcon width={16} height={16} stroke="#1C2640" />
          </div>
        </div>
        {/* Redirecting company career page section */}
        <div
          className={styles.companyCareerPageSection}
          onClick={() => handleCareerPageClick(job.career_site_company_name)}
          onKeyDown={() => handleCareerPageClick(job.career_site_company_name)}
          tabIndex={0}
          role="button"
        >
          <div className={styles.companyPageText} style={{ textDecoration: 'none' }}>
            Go to Company page
          </div>
          <div className={styles.longArrow}>
            <LongArrow fill="white" />
          </div>
        </div>
      </div>
    );
  };

  const JobCards = ({ companyCount }: { companyCount: number }) => {     //eslint-disable-line
    return (
      <div className={styles.jobCardContainer}>
        {/* Job cards hero heading */}
        <div className={styles.jobCardsHeading}>
          Explore jobs from top companies
        </div>
        {/* Job cards hero text */}
        <div className={styles.center}>
          <div className={styles.jobCardsText}>
            Looking for a new role or kickstarting your career?
            Take a look at companies that are hiring using the Interviewer.AI platform.
          </div>
        </div>
        {/* Available job cards */}
        <div className={styles.jobCardsContainer}>
          {
            companylist.length === 0 && fetchingData && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '200px',
                }}
              >
                <ClipLoadingComponent />
              </div>
            )
          }
          {
            companylist.length === 0 && !fetchingData ? (
              <EmptyState />
            ) : (
              <div className={styles.cardGrid}>
                {
                  companylist.map(job => (
                    <Card job={job} />
                  ))
                }
              </div>
            )
          }
        </div>
        {/* Pagination for cards */}
        <div className={styles.paginateContainer}>
          <Pagination
            className={styles.paginationBar}
            currentPage={currentPage}
            totalCount={companyCount}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          />
        </div>
      </div>
    );
  };

  const Banner = () => (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerImgAndText}>
        <div className={styles.bannerImg}>
          <img src={bannerImg} className={styles.bannerImage} alt="bannerImg" />
        </div>
        <div className={styles.bannerTextAndButton}>
          <div className={styles.bannerPrimaryText}>
            The most-advanced, all-in-one video recruitment software for hiring globally
          </div>
          <span className={styles.signupBannerBtnSection}>
            <a
              className={styles.bannerSignupBtn}
              style={{ textDecoration: 'none' }}
              href={`${clientConfig.default.INTERVIEWERAI_DASHBOARD_DOMAIN}signup/`}
            >
              Sign up
            </a>
          </span>
        </div>
      </div>
    </div>
  );

  const Footer = () => (
    <div className={styles.footerContainer}>
      <div className={styles.footerLineSeperator}>
        <hr />
      </div>
      <div className={styles.copyrightText}>
        Copyright © 2021 Interviewer.AI. | All rights reserved.
      </div>
    </div>
  );

  return (
    <div className={styles.parentContainer}>
      {/* Meta tags */}
      <MetaTags
        location={window.location}
      />

      {/* Notification */}
      {/* <div className={styles.topNotificationSection}>
        <span>
          <NotificationBell width={14} height={17} />
        </span>
        <span className={styles.notificationText}>
          Click here to learn more about our product updates
        </span>
      </div> */}

      <section className={styles.topContentSection}>
        {/* Navigation bar */}
        <div className={styles.navigationBarContainer}>
          <NavigationBar />
        </div>
        {/* Content of section */}
        <div className={styles.topSectionContent}>
          <TopSectionContent />
        </div>
      </section>

      <section className={styles.jobCardsSection}>
        <JobCards companyCount={totalCompanies} />
      </section>

      {/* Bannner */}
      <section className={styles.bannerSection}>
        <Banner />
      </section>
      {/* Footer */}
      <section className={styles.footerSection}>
        <Footer />
      </section>
    </div>
  );
};

export default withTranslation()(CompaniesHiringPage);
