import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import GoogleAnalytics from 'react-ga4';
import Mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/browser';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import fetch from 'isomorphic-fetch';

import './css/index.scss';
import i18n from './locales/i18n';
import clientConfig from './config/config';
import LoadingComponent from './components/LoadingComponent';
import ResumeParserDemo from './pages/ResumeParserDemo';
import Login from './pages/Candidate/Login/Login';
import Callback from './pages/Candidate/Login/Callback';
import DigitalProfile from './pages/Candidate/DigitalProfile/DigitalProfile';
import auth from './auth/auth';
import { AUTH_REDIRECT_KEY, SUPPORTED_LANGUAGES } from './utils/constants';
import Logout from './pages/Candidate/Logout/Logout';
import CandidateDetailsNew from './pages/Candidate/CandidateDetails/CandiateDetailsNew';
import TermsOfConditions from './pages/TermsOfConditions';
import CompanyDetails from './pages/CompanyDetails';
import CompaniesHiringPage from './pages/CompaniesHiringPage';
import CandidateSettings from './pages/Candidate/CandidateSettings';
// import BillingPage from './pages/BillingPage';

const ApplicationScreen = lazy(() => import('./pages/InterviewSignUpScreen'));
const ApplySendSuccessfulScreen = lazy(() => import('./pages/ApplySendSuccessful'));
const OrganisationIntroductionVideo = lazy(() => import('./pages/OrganisationIntroductionVideo'));
const NotFoundScreen = lazy(() => import('./pages/NotFoundScreen'));
const InterviewScreen = lazy(() => import('./App'));
const WorkmapAssessmentScreen = lazy(() => import('./pages/WorkmapAssessment/WorkmapAssessmentScreen'));
const DomainQuestionScreen = lazy(() => import('./pages/DomainQuestions/DomainQuestionScreen'));
const MockInterviewPage = lazy(() => import('./pages/MockInterviewPage'));


const BillingPage = lazy(() => import('./pages/BillingPage'));


const SubscriptionSuccess = lazy(() => import('./pages/Subscription/SubscriptionSuccess'));

const measurementId = 'G-QYVKK3DQJZ';
// Initialize ReactGA
GoogleAnalytics.initialize(measurementId);
Mixpanel.init(clientConfig.default.MIXPANEL_TOKEN);
if (clientConfig.default.SENTRY_DSN) {
  Sentry.init({
    dsn: clientConfig.default.SENTRY_DSN,
    environment: process.env.NODE_ENV,
  });
}


const WaitingComponent = Component => props => (
  <Suspense fallback={<LoadingComponent />}>
    <Component {...props} />
  </Suspense>
);

const requireAuth = (to, from, next) => {
  const { meta, location } = to;
  if (meta.auth) {
    if (auth.isAuthenticated()) {
      next();
    } else {
      sessionStorage.setItem(AUTH_REDIRECT_KEY, location.pathname);
      auth.login();
    }
  } else {
    next();
  }
};

// we can use this once we have the translation ready
// eslint-disable-next-line
const requireInterviewLanguage = async (to, from, next) => {
  const { meta, match } = to;
  try {
    if (meta.checkInterviewLanguage) {
      const currentSessionLanguage = sessionStorage.getItem('LANGUAGE');
      if (currentSessionLanguage) {
        console.log('continuing session with ', currentSessionLanguage);
        i18n.changeLanguage(currentSessionLanguage);
        next();
      } else {
        const {
          params: { interviewId },
        } = match;
        const req = await fetch(`/api/interview/${interviewId}/language`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (req.status === 200) {
          const res = await req.json();
          const { language } = res;
          if (SUPPORTED_LANGUAGES.includes(language)) {
            i18n.changeLanguage(language);
            sessionStorage.setItem('LANGUAGE', language);
          }
          next();
        }
      }
    }
  } catch (error) {
    // default to english if any error
    console.error(error);
  }
  next();
};

const getLanguage = { checkInterviewLanguage: true };

ReactDOM.render(
  <BrowserRouter>
    <GuardProvider
      guards={[requireAuth, requireInterviewLanguage]}
      error={NotFoundScreen}
      loading={LoadingComponent}
    >
      <Switch>
        <GuardedRoute
          exact
          meta={getLanguage}
          path="/interview/apply-success"
          component={WaitingComponent(ApplySendSuccessfulScreen)}
        />
        <GuardedRoute
          meta={getLanguage}
          path="/interview/:interviewId/:candidateId/:slug"
          component={WaitingComponent(InterviewScreen)}
        />

        <GuardedRoute
          meta={getLanguage}
          path="/interview/:interviewId"
          component={WaitingComponent(ApplicationScreen)}
        />

        <GuardedRoute
          meta={getLanguage}
          path="/workmap/:interviewId/:candidateId/:slug"
          component={WaitingComponent(WorkmapAssessmentScreen)}
        />

        <GuardedRoute
          meta={getLanguage}
          path="/domain/:interviewId/:candidateId/:slug"
          component={WaitingComponent(DomainQuestionScreen)}
        />

        <GuardedRoute
          meta={getLanguage}
          path="/introductionVideo/:interviewId/:candidateId/:slug"
          component={WaitingComponent(OrganisationIntroductionVideo)}
        />
        <GuardedRoute
          exact
          path="/mockInterview/:orgId"
          component={WaitingComponent(MockInterviewPage)}
        />
        <Route
          path="/demo/resume-parser/software-engineer"
          render={props => (
            <ResumeParserDemo job="Software Engineer" {...props} />
          )}
        />
        <Route
          path="/demo/resume-parser/sales-and-bd"
          render={props => (
            <ResumeParserDemo job="Sales / Business Development" {...props} />
          )}
        />
        <Route
          path="/interviewer-ai/terms-condition"
          component={WaitingComponent(TermsOfConditions)}
        />
        <Route
          exact
          path="/"
          component={WaitingComponent(CompaniesHiringPage)}
        />
        <Route exact path="/callback" component={WaitingComponent(Callback)} />
        <Route exact path="/user/settings" component={WaitingComponent(CandidateSettings)} />
        <Route
          exact
          path="/subscription/payment/success"
          component={WaitingComponent(SubscriptionSuccess)}
        />
        <Route exact path={['/candidate']} component={WaitingComponent(DigitalProfile)} />
        <Route path="/user">
          {/* <NavigationBar /> */}
          <Switch>
            <GuardedRoute
              meta={{ auth: true }}
              path="/user/assessment/:interviewId/:candidateId"
              component={WaitingComponent(CandidateDetailsNew)}
            />
            <Route path="/user/login" component={WaitingComponent(Login)} />
            <Route path="/user/logout" component={WaitingComponent(Logout)} />
            <Route path="/user/billing" component={WaitingComponent(BillingPage)} />
            <Route component={WaitingComponent(NotFoundScreen)} />
          </Switch>
        </Route>
        <GuardedRoute
          meta={getLanguage}
          path="/:companyname/:interviewId"
          component={WaitingComponent(ApplicationScreen)}
        />
        <Route
          path="/:companyname"
          component={WaitingComponent(CompanyDetails)}
        />
        <Route component={WaitingComponent(NotFoundScreen)} />
      </Switch>
    </GuardProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
