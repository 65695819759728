// @flow
import 'es6-promise/auto';
import fetch from 'isomorphic-fetch';
import React, { Fragment, useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import clientConfig from '../config/config';
import Brand from '../assets/NewBrandDark.svg';
import BrandInverse from '../assets/NewBrandLight.svg';
import '../css/InterviewSignUpScreen.css';
import '../css/ResumeParser.css';
import ClipLoadingComponent from '../components/ClipLoadingComponent';
import { generateRandomCharacters } from '../utils/utils';

const FieldValuePair = ({ field, value }: {
  field: string,
  value: string,
}) => (
  <Fragment>
    <Col xs={5}>{field}</Col>
    <Col xs={7}>{value}</Col>
    <div className="clearfix" />
  </Fragment>
);

const getDurationFromExperience = (experience) => {
  if (experience.isCurrent && experience.startYear !== -1) {
    return `${experience.startYear} - Present`;
  }

  if (experience.isCurrent) {
    return 'Present';
  }

  if (experience.startYear !== -1 && experience.endYear !== -1) {
    return `${experience.startYear} - ${experience.endYear}`;
  }

  return '';
};

const JOB_CONFIG = {
  'Software Engineer': {
    requisitionId: '2',
    description: (
      <p>
        Become part of the Interviewer.AI Engineering Team and be responsible
        for the design, implementation and integration of our services and applications,
        participating in every layer of the technical stack. You&apos;ll code and deliver
        features with special attention to speed & performance, security, automation,
        scalability and sustainability.
      </p>
    ),
    backgroundImageStyle: 'linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("https://res.cloudinary.com/rbmh-platforms/image/upload/w_1000,c_fill,g_auto/rbjobs/staging/TechnologyFocus.jpg")',
  },
  'Sales / Business Development': {
    requisitionId: '3',
    description: (
      <p>
        At Interviewer.AI, we are focused on building the penultimate step to hire great talent.
        We are looking for a highly motivated individual with an entrepreneurial spirit and strong
        business acumen who is passionate about helping customers bring transparency, speed,
        and efficiency into the hiring process using Interviewer.AI.
      </p>
    ),
    backgroundImageStyle: 'linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80")',
  },
};

const ResumeParserDemo = ({ job }: { job: string }) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [results, setResults] = useState({});
  const [error, setError] = useState('');

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: '.pdf',
    onDropRejected: (rejectedFiles) => {
      const rejectedFileNames = rejectedFiles.map(f => f.file.name);
      setError(`${t('resumeParser.error')}: ${rejectedFileNames.join(', ')}`);
    },
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length < 1) {
        return;
      }
      setUploading(true);
      setError('');
      setResults({});
      // call API to retrieve scoring
      try {
        const formData = new FormData();
        formData.append('resumeFile', acceptedFiles[0]);
        formData.append('requisitionId', JOB_CONFIG[job].requisitionId);
        formData.append('candidateId', `2-${generateRandomCharacters()}`);
        const req = await fetch(`${clientConfig.default.API_ENDPOINT}/resume/candidate-score`, {
          method: 'POST',
          body: formData,
        });

        const res = await req.json();
        const { data } = res;
        if (data.error) {
          throw new Error(data.message);
        }
        setResults(data);
        setUploading(false);
      } catch (e) {
        console.log(e);
        setError(e.message);
      } finally {
        setUploading(false);
      }
    },
  });


  return (
    <div>
      <div>
        <div className="flex-column">
          <div
            className="text-center resume-parser__background-image"
            style={{
              backgroundImage: JOB_CONFIG[job].backgroundImageStyle,
            }}
          >
            <img
              src={BrandInverse}
              alt="Interviewer.AI Logo"
            />
          </div>
          <Container className="apply-screen">
            <h2>
              {job}
            </h2>
            <h4>{t('resumeParser.title')}</h4>
            {
              JOB_CONFIG[job].description
            }
            <p>
              {t('resumeParser.description')}
            </p>
            <section className={`resume-parser__dropzone ${isDragActive ? 'resume-parser__dropzone-active' : ''}`}>
              <div {...getRootProps()} className="resume-parser__dropzone--div">
                <input {...getInputProps()} />
                <div className="resume-parser__dropzone--div resume-parser__dropzone--div-padding">
                  {
                    uploading ? (
                      <span>
                        {t('uploading')}
                        ...
                        <ClipLoadingComponent />
                      </span>
                    ) : t('resumeParser.dragResume')
                  }
                </div>
              </div>
            </section>
            {
              error && (
                <p>
                  {error}
                </p>
              )
            }
            {
              Object.keys(results).length > 0 && (
                <Fragment>
                  <h3 className="resume-parser__parsed-results--header">{t('resumeParser.parsedResults')}</h3>
                  <Col md={6} lg={6}>
                    <h4 className="resume-parser__parsed-results--section-header">{t('resumeParser.personalDetails')}</h4>
                    <FieldValuePair field={t('resumeParser.name')} value={results.resume.name} />
                    <FieldValuePair field={t('resumeParser.email')} value={results.resume.emails[0]} />
                    <FieldValuePair field={t('resumeParser.phone')} value={results.resume.phones[0]} />
                    <h4 className="resume-parser__parsed-results--section-header">{t('resumeParser.experience')}</h4>
                    {
                      results.resume.yearsOfExperience && (
                        <div className="resume-parser__parsed-results--experience-div">
                          <FieldValuePair
                            field={t('resumeParser.totalYoE')}
                            value={(Math.round(results.resume.yearsOfExperience * 100) / 100)
                              .toString()}
                          />
                        </div>
                      )
                    }
                    {
                      results.resume.experiences.map((experience) => {
                        const durationText = getDurationFromExperience(experience);
                        return (
                          <div className="resume-parser__parsed-results--experience-div" key={experience.experienceOrg}>
                            <FieldValuePair field={t('resumeParser.company')} value={experience.experienceOrg} />
                            <FieldValuePair field={t('resumeParser.position')} value={experience.title} />
                            {
                              durationText && <FieldValuePair field={t('resumeParser.position')} value={durationText} />
                            }
                          </div>
                        );
                      })
                    }
                  </Col>
                  <Col md={6} lg={6}>
                    <h4 className="resume-parser__parsed-results--section-header">{t('resumeParser.skills')}</h4>
                    <Col xs={12}>
                      {
                        results.resume.skills.map(skill => (
                          <div className="resume-parser__parsed-results--badge" key={skill}>{skill}</div>
                        ))
                      }
                    </Col>
                    <div className="clearfix" />
                    <h4 className="resume-parser__parsed-results--section-header">{t('resumeParser.education')}</h4>
                    {
                      results.resume.educations.map((education) => {
                        const durationText = getDurationFromExperience(education);
                        return (
                          <div className="resume-parser__parsed-results--experience-div" key={education.educationOrg}>
                            <FieldValuePair field={t('resumeParser.institute')} value={education.educationOrg} />
                            <FieldValuePair field={t('resumeParser.degree')} value={education.degree} />
                            <FieldValuePair field={t('resumeParser.major')} value={education.major} />
                            {
                              durationText && <FieldValuePair field={t('resumeParser.duration')} value={durationText} />
                            }
                          </div>
                        );
                      })
                    }
                    <h4 className="resume-parser__parsed-results--section-header">{t('resumeParser.score')}</h4>
                    <FieldValuePair field={t('resumeParser.overall')} value={results.score.overall} />
                    <p className="resume-parser__parsed-results--score-description">
                      <small>
                        <i>
                          {t('resumeParser.betterFitNote')}
                        </i>
                      </small>
                    </p>
                  </Col>
                </Fragment>
              )
            }
          </Container>
          <Col sm={12} md={12} lg={12}>
            <div className="text-center apply-screen__powered-by-div">
              <p>{t('poweredBy')}</p>
              <img src={Brand} alt="Interviewer.AI brand logo" />
            </div>
          </Col>

        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};


export default ResumeParserDemo;
