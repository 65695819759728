import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import interviewerAILogo from '../assets/interviewer.AI.png';
import styles from '../css/TermsOfConditions.module.scss';

const TermsOfConditions = () => {
  const history = useHistory();
  const handleClick = () => {
    history.goBack();
  };

  const renderLogo = (img, isDesktop) => { // eslint-disable-line
    return (
      <div style={{
        borderRadius: isDesktop ? '10px' : '5px',
        padding: isDesktop ? '' : '.3rem .3rem',
      }}
      >
        <div
          style={{
            backgroundImage: `url(${img})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            minWidth: isDesktop ? '55px' : '55px',
            minHeight: isDesktop ? '55px' : '55px',
            maxWidth: isDesktop ? '55px' : '55px',
            maxHeight: isDesktop ? '55px' : '55px',
            borderRadius: isDesktop ? '9px' : '5px',
          }}
        />
      </div>
    );
  };

  return (
    <div
      className={
        isMobile
          ? styles.termsAndCondtionSectionForMobile
          : styles.termsAndCondtionSection
      }
    >
      {
        renderLogo(interviewerAILogo, false)
      }
      <div
        className={isMobile ? styles.mainSectionForMobile : styles.mainSection}
      >
        <p
          className={
            isMobile ? styles.companyNameForMobile : styles.companyName
          }
        >
          Interviewer.AI
        </p>
        <p
          className={
            isMobile
              ? styles.termsConditionHeadingForMobile
              : styles.termsConditionHeading
          }
        >
          Terms of Condition
        </p>
        <div className={isMobile ? styles.termsForMobile : styles.terms}>
          <p className={styles.termsDescription}>
            The underlying rationale of the GDPR is to empower you (Data
            Subjects) to control how your personal data is processed. We have a
            responsibility to protect your individual privacy and autonomy, and
            to comply with the requirements of the GDPR. More importantly, we
            genuinely believe the world will be a better place if you are
            empowered to control the use of your own data. We at Interviewer.AI
            have invested heavily in enabling you to exercise your rights under
            the GDPR to the maximum extent possible.
            <br />
            Contact Details for Redress:
            <p className={styles.termsHeading}>Data Protection Officer</p>
            <p className={styles.termsDescription}>
              INTERVIEWER.AI PTE. LTD.
              <br />
              176 Orchard Rd, #05-05, Singapore 238843
              <br />
              hello@interviewer.ai
              <br />
              You have the following rights under GDPR:
            </p>
            <ol type="i">
              <li className={styles.rightsUnderDdpr}>
                Right to be Informed.
                <span className={styles.termsDescription}>
                  &nbsp;You have the right to be informed about the collection
                  and use of your personal data. This is a key transparency
                  requirement under the GDPR. How we help: The privacy policy is
                  designed to inform you about how your information is
                  processed, as well as what your rights are.
                </span>
              </li>
              <br />
              <li className={styles.rightsUnderDdpr}>
                Right of Access.
                <span className={styles.termsDescription}>
                  &nbsp;You have the right to access your personal data and
                  supplementary information to be aware of and verify the
                  lawfulness of processing. How we help: After signing in, we
                  allow you to access your personal data in JSON format, along
                  with supplementary information in your privacy settings.
                </span>
              </li>
              <br />
              <li className={styles.rightsUnderDdpr}>
                Right to Rectification.
                <span className={styles.termsDescription}>
                  &nbsp;You have the right to have personal data rectified if it
                  is inaccurate or incomplete. How we help: After signing in, we
                  allow you to edit your personal data via your profile page.
                </span>
              </li>
              <br />
              <li className={styles.rightsUnderDdpr}>
                Right to Erasure.
                <span className={styles.termsDescription}>
                  &nbsp;You have the right to request the deletion or removal of
                  your personal data where there is no compelling reason for its
                  continued processing. How we help: After signing in, we allow
                  you to request that your account be restricted. Your data will
                  be removed from Interviewer.AI and placed in restricted
                  archival storage for 2 years before being deleted to comply
                  with relevant laws governing employment records retention.
                </span>
              </li>
              <br />
              <li className={styles.rightsUnderDdpr}>
                Right to Restrict Processing.
                <span className={styles.termsDescription}>
                  &nbsp;You have the right to ‘block’ or suppress processing of
                  your personal data. How we help: After signing in, we allow
                  you to request that your account be restricted. Your data will
                  be removed from Interviewer.AI and placed in restricted
                  archival storage for 2 years before being deleted to comply
                  with relevant laws governing employment records retention.
                </span>
              </li>
              <br />
              <li className={styles.rightsUnderDdpr}>
                Right to Data Portability.
                <span className={styles.termsDescription}>
                  &nbsp;You have the right to obtain and reuse your personal
                  data for your own purposes across different services. How we
                  help: After signing in, we allow you to export your personal
                  data in JSON format in your privacy settings.
                </span>
              </li>
              <br />
              <li className={styles.rightsUnderDdpr}>
                Right to Object.
                <span className={styles.termsDescription}>
                  &nbsp;You have the right to object to processing based on
                  legitimate interests or the performance of a task in the
                  public interest/exercise of official authority (including
                  profiling), direct marketing (including profiling), and
                  processing for purposes of scientific/historical research and
                  statistics. How we help: We allow you to object to processing
                  by changing your email settings, by exercising your right to
                  restrict processing, or by contacting hello@interviewer.ai.
                </span>
              </li>
              <br />
              <li className={styles.rightsUnderDdpr}>
                Automated individual decision-making, including profiling.
                <span className={styles.termsDescription}>
                  &nbsp;You have the right not to be subject to a decision based
                  solely on automated processing, including profiling, which
                  produces legal or similarly significant effects. How we help:
                  The data we collect is not necessarily used in automatic
                  decision making. In case our client uses automated decision
                  making, you may require human intervention. Please contact the
                  Controller who invited you to complete an Assessment.
                </span>
              </li>
              <br />
            </ol>
            <p className={styles.termsHeading}>DETAILED PRIVACY POLICY</p>
            <p className={styles.termsDescription}>
              For our European customers, we want to explain how we handle
              privacy and protection of your data at Interviewer.AI
              (INTERVIEWER.AI PTE. LTD. incorporated in Singapore with Unique
              Entity Number UEN 201837827Z – “Interviewer.AI”, “we”, “us”
              or“our”) when you visit our website at
              <a href=" https://interviewer.ai/"> https://interviewer.ai/</a>
              or you use our services.
              <br />
              <br />
              Your privacy is important to Interviewer.AI. Interviewer.AI
              strives to protect your privacy at all times and to apply the
              highest ethical and regulatory standards. That is why all
              communication by and in our Interviewer.AI Software-as-a-Service
              (the “SaaS”) is encrypted by state-of-the-art SSL encryption. This
              Interviewer.AI Privacy Policy (the “Privacy Policy”) tells you
              about how we collect, use, disclose, transfer, and store your
              information, as well as the choices you have regarding your
              information including but not limited to personally identifiable
              information (the ”Personal Data”). It also gives you information
              about our use of cookies (cf. detailed description and definition
              below), analytics tools, and marketing and advertising practices.
              <br />
              <br />
              Other than as expressly set out in this Privacy Policy or as
              otherwise required or permitted by law, we will not share, sell or
              distribute any of the information you provide to us without your
              consent (that in certain cases needs to be expressly granted).
              <br />
              WE COLLECT, USE, TRANSFER OR FORWARD PERSONAL DATA IN
              COMPLIANCE WITH THE PRIVACY PROTECTION LAWS OF THE FEDERAL
              REPUBLIC OF GERMANY AS WELL AS WITH THE PRIVACY REGULATIONS OF THE
              EUROPEAN UNION.
            </p>
          </p>
          <ol type="I">
            <li className={styles.rightsUnderDdpr}>
              Who is responsible for data processing and who can you contact?
              <p className={styles.termsDescription}>
                As part of our provision of the Interviewer.AI SaaS to you and
                from our interactions with you, Interviewer.AI needs to collect
                Personal Data either as controller or as processor on behalf of
                an employer. If you visit our website at Interviewer.AI (
                <a href="https://interviewer.ai/">https://interviewer.ai/</a>
                )
                we process personal data as controller. Please ask us if you
                would like information about the Personal Data we store. We will
                gladly provide this for you for free as quickly as possible. We
                can also send this information to you electronically over email.
                You can contact our data protection officer at: Data Protection
                Officer
                <br />
                <a href="mailto: hello@interviewer.ai">hello@interviewer.ai</a>
              </p>
            </li>
            <li className={styles.rightsUnderDdpr}>
              Which data are being processed and what are the sources of these
              data?
              <p className={styles.termsDescription}>
                We process personal data (Art. 4 Nr. 1 GDPR) which we receive in
                the course of conducting our business as provider of a
                SaaS-Product for AI video recruiting. Our service can be used by
                Attendees (hereinafter: “you”) in order to provide additional
                information for employer in a job application process. Typically
                we work on behalf of your possible employer (“employer”) who
                will be our customer. We provide our services to employers in
                order to enable him to use our services for its application
                process. So please also take notice of the Additional
                information with respect to Video-Recruiting (see below) and of
                the privacy policy of the respective employer to obtain more
                detailed information about the processing of personal data
                during a specific application process. However, we also process
                personal data as data controller of you if you visit our
                website.
              </p>
            </li>
            <li className={styles.rightsUnderDdpr}>
              For what purpose and on what legal basis are the data processed?
              <p className={styles.termsDescription}>
                We process personal data in order to be able to provide our
                services and in order to receive and reply your requests. We
                process personal data in accordance with the following data
                protection-related provisions:
                <br />
                <ol type="a">
                  <li className={styles.rightsUnderDdpr}>
                    <p className={styles.termsDescription}>
                      Processing personal data with your consent (Art. 6 para. 1
                      a GDPR):
                      <br />
                      We process data according to Art. 6 para 1 a GDPR in order
                      to communicate with you and advert for our services.
                    </p>
                  </li>
                  <li className={styles.rightsUnderDdpr}>
                    <p className={styles.termsDescription}>
                      Processing personal data to full contractual obligations
                      (Art. 6 para. 1 b GDPR):
                      <br />
                      If we enter into a contractual relation (including the
                      initiation of contractual relation) the processing of
                      personal data takes place for the provision of our
                      services as cloud software provider to Art. 6 para. 1 b
                      GDPR.
                    </p>
                  </li>
                  <li className={styles.rightsUnderDdpr}>
                    <p className={styles.termsDescription}>
                      Processing personal data to full legal requirements (Art.
                      6 para. 1 c GDPR):
                      <br />
                      In the event that our company is subject to a legal
                      obligation that requires the processing of personal data,
                      such as for example the fulfillment of tax obligations,
                      the processing of personal data is made pursuant to Art. 6
                      para. 1 lit. c GDPR.
                    </p>
                  </li>
                  <li className={styles.rightsUnderDdpr}>
                    <p className={styles.termsDescription}>
                      Processing personal data according to Art. 6 para. 1 d
                      GDPR:
                      <br />
                      In exceptional cases, the processing of personal data may
                      be required in order to protect the vital interests of the
                      data subject or another natural person. This could, for
                      example, be the case where a visitor will be injured in
                      our premises and in consequence, his name, age, health
                      insurance data, or other vital information need to be
                      transmitted to a doctor, hospital or other third parties.
                      In that event, the processing will be made pursuant to
                      Art. 6 para. 1 lit. d GDPR.
                    </p>
                  </li>
                  <li className={styles.rightsUnderDdpr}>
                    <p className={styles.termsDescription}>
                      Processing personal data according to Art. 6 para. 1 f
                      GDPR:
                      <br />
                      Finally, data processing activities can be conducted on
                      the basis of Art. 6 para. 1 lit. f GDPR which covers data
                      processing activities which are do not fall under any of
                      the aforementioned legal provisions and which covers data
                      processing which is necessary for the purposes of the
                      legitimate interests pursued by us or a third party and
                      provided that such interests are not overridden by the
                      interests or fundamental rights and freedoms of the data
                      subject.
                    </p>
                  </li>
                </ol>
              </p>
            </li>
            <li className={styles.rightsUnderDdpr}>
              Server Logfiles
              <p className={styles.termsDescription}>
                We collect and store information on the basis of Art. 6 para 1
                lit. f GDPR about your visits of our website in so-called
                log-files on our server.
                <br />
                The logfiles contain data that your browser is automatically
                sending to us, such as:
                <br />
                shortended IP-address
                <br />
                browser type/ browser version
                <br />
                your operating system
                <br />
                referrer URL (or the website visited previously)
                <br />
                date and time of the server request
                <br />
                amount of transmitted data
                <br />
                your internet service provider
                <br />
                These data will be collected and processed only for the purpose
                of measuring the statistics of our website performance. These
                data will not be connected with data from other data sources.
              </p>
            </li>
            <li className={styles.rightsUnderDdpr}>
              Use of Cookies
              <p className={styles.termsDescription}>
                Our Website uses cookies on the basis of Art. 6 para 1 lit. f
                GDPR for cookies that are technically necessary and on the basis
                of Art. 6 para 1 lit. a GDPR. A cookie is a text file that is
                placed on the device of the user (PC, tablet, smartphone etc.)
                and stores certain information referring to the device. If you
                visit our website from the respective device our server receives
                information from a cookie. Our server can use this information
                for different purposes. For example can cookies be used for
                tailoring advertising to the user or in order to provide
                statistics for the use of the website. In your browser settings
                you can allow or deactivate cookies. In the case of deactivating
                cookies, however, some functionality of our website might not
                work.
              </p>
            </li>
            <li className={styles.rightsUnderDdpr}>
              Period of Data Storage
              <p className={styles.termsDescription}>
                We process and store personal data only for the period, which is
                required to meet the purpose of processing, or as long and to
                the extent as statutory laws require us to process and/or store
                such data. If the purpose of processing does not apply anymore
                and the applicable statutory retention requirement expires, we
                will as a matter of routine erase data or restrict the
                processing of data in accordance with the applicable statutory
                laws.
              </p>
            </li>
            <li className={styles.rightsUnderDdpr}>
              Information about the transfer of personal data to a third country
              <p className={styles.termsDescription}>
                We use various cloud services as part of our service, i.e.
                US-based providers or providers from other states outside EU/EEA
                or Singapore who also process personal data on our behalf (e.g.
                name, e-mail address and possibly others).
                <br />
                We expressly point out that, with regard to the USA, no adequacy
                decision of the European Commission has been issued so far. We
                will only transfer personal data to a provider in the USA if
                this transmission is permitted in accordance with the so-called
                Privacy Shield Agreement and / or the transmission is
                legitimized by standard data protection clauses (standard
                contractual clauses).
                <br />
                The guarantees for privacy shield can be found here:
                <br />
                <a href="https://www.privacyshield.gov/EU-US-Framework">
                  https://www.privacyshield.gov/EU-US-Framework
                </a>
                <br />
                The standard contract clauses can be found here:
                <br />
                <a href="http://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2010:039:0005:0018:DE:PDF">
                  http://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2010:039:0005:0018:DE:PDF
                </a>
              </p>
            </li>
            <li className={styles.rightsUnderDdpr}>
              Is there an automated decision-making process?
              <p className={styles.termsDescription}>
                We do not use automated decision-making processes under Art. 22
                GDPR for initiating decisions on the establishment or carrying
                out of the business relationship, which would have legal
                consequences for you or would have a similar significant
                negative impact. As far as an employer uses our SaaS as a part
                of a job application process our SaaS please take notice of the
                Additional information with respect to Video-Recruiting (see
                below) and of the privacy policy of the respective employer.
              </p>
            </li>
            <li className={styles.rightsUnderDdpr}>
              Additional information with respect to Video-Recruiting
              <br />
              <br />
              What is the purpose of the data processing and who is the data
              controller?
              <p className={styles.termsDescription}>
                he SaaS has the purpose to provide you an objective personality
                profile of you by analyzing video and audio recordings of
                communications based on artificial intelligence technology.
                Interviewer.AI provides a SaaS on behalf of employers who will
                be acting as data controller. The SaaS measures facial
                expressions, voice, and speech.
                <br />
                You can find more detailed information about methodology at:
                <a href="https://www.Interviewer.AI.com/science">
                  https://www.Interviewer.AI.com/science
                </a>
                <br />
                Therefore, you start an asynchronous video interview (a “Video
                Interview”) via a web application, which may be recorded and
                stored for the application process.
                <br />
              </p>
              Which kind of data are processed?
              <br />
              <p className={styles.termsDescription}>
                When using the SaaS you may upload and share your own documents
                (the “User Generated Content”). Your video and audio recordings
                may include Personal Data, Skills and other information from
                your resume.
                <br />
                While providing our SaaS we collect and store the following
                information about your Video Interview:
                <br />
                Email ID
                <br />
                Phone Number
                <br />
                Details within a Resume
                <br />
                LinkedIn Profile
                <br />
                IP Address
                <br />
                With your consent given according to Art. 9 II a GDPR to the
                respective employer the Video Recording will be used in order to
                process data as follows:
                <br />
                <p className={styles.rightsUnderDdpr}>
                  Video analysis:
                  <span className={styles.termsDescription}>
                    &nbsp; Interviewer.AI evaluates your Video Interview
                    recordings using computer vision. .The algorithm will
                    analyse your facial expressions to look for positive
                    emotions or negative emotions. By using the Interviewer.AI
                    SaaS, you expressly agree to the quantification and
                    evaluation of your recorded facial expressions.
                  </span>
                </p>
                <p className={styles.rightsUnderDdpr}>
                  Voice analysis:
                  <span className={styles.termsDescription}>
                    &nbsp; The SaaS records your voice and quantifies voice
                    patterns, e.g. arousal, and valence scores. The quantified
                    voice patterns are evaluated with regard to different
                    effects (e.g. emotions) and enable the Interviewer.AI SaaS
                    to give you feedback on the effects of your voice.
                  </span>
                </p>
                <p className={styles.rightsUnderDdpr}>
                  Speech analysis:
                  <span className={styles.termsDescription}>
                    &nbsp; The SaaS quantifies your voice-to-text, i.e., your
                    spoken words, and transcribes them. Then the spoken words
                    are analyzed with existing NLP libraries (e.g., sentiment).
                    This is done to give feedback about, for instance, people’s
                    clarity of speech, word count, etc. By using our SaaS, you
                    expressly consent to the processing and storage of this
                    information.
                    <br />
                    You can withdraw your consent to process these data at any
                    time. You are not obliged to participate in Video Recruiting
                    by law or by contract and you can also proceed in the
                    application process if you decide not to participate.
                  </span>
                </p>
              </p>
              Where are data stored and who will have access to your personal
              data?
              <p className={styles.termsDescription}>
                We will have access to your personal data in our role as data
                processor for the respective employer who is our customer. Based
                on our contractual relation to our customers we are obliged to
                process personal data only for the purpose of the customer. With
                your consent, we may anonymize personal data and process
                anonymized data in order to enhance our software and services.
                In the processing of your personal data we strictly follow the
                directions of our customers.
                <br />
                Like many companies, all Interviewer.AI data will be stored and
                processed on Amazon Web Services (AWS) or Google Cloud Platform
                (the “GoogleCloud”). Please see AWS Privacy Policy (
                <a href="https://aws.amazon.com/privacy/">
                  https://aws.amazon.com/privacy/
                </a>
                ) or Google’s Privacy Policy (
                <a href="https://cloud.google.com/security/privacy/">
                  https://cloud.google.com/security/privacy/
                </a>
                ) for more information. Interviewer.AI limits AWS Cloud to store
                data exclusively on servers located in EU for all customers in
                Europe.
              </p>
            </li>
            <li className={styles.rightsUnderDdpr}>
              Your Interviewer.AI Account – Information for employers with User
              Account
              <br />
              <span className={styles.termsDescription}>
                In order to activate and/or use some of our SaaS, you will need
                to create an account (the “Account”). During the process of
                setting up your account, we will ask you for certain Personal
                Data such as your
                <br />
                e-mail address; personal name;
                <br />
                company name;
                <br />
                company website;
                <br />
                your position/department
                <br />
                number of positions you usually hire
                <br />
                address;
                <br />
                country;
                <br />
                IP Address; and
                <br />
                Time-stamp of Account registration.
                <br />
                We need this information to allow you to activate, manage or use
                your Interviewer.AI products and services. We may associate your
                email address and other Personal Data (such as name, telephone
                number and address) with your Account and access to our products
                or services. For further information, please see the User
                Experience and Statistics section below.
              </span>
              <br />
              <br />
              Device-related information:
              {' '}
              <span className={styles.termsDescription}>
                &nbsp; In addition, Interviewer.AI SaaS collects device-related
                information, such as model information of the hardware you are
                using, device identifiers, or the version of your operating
                system. Interviewer.AI may link this information to your user
                profile.
              </span>
              <br />
              Log data:
              <span className={styles.termsDescription}>
                &nbsp; When you use our SaaS, we may collect and store certain
                information in serverlogs. This may include details about your
                behavior patterns when using the SaaS, such as the average
                length of your records, information about device features and
                events such as crashes, system activity, hardware
                configurations, browser variant and language, time of your
                request, referral URLs, and cookies.
              </span>
              <br />
              Location-related information:
              <span className={styles.termsDescription}>
                &nbsp; When you use the SaaS, we may collect and process
                information about your location, such as IP addresses or WLAN
                access points. This information is used exclusively to
                personalize our services (e.g. automatic recognition of your
                native language) and to improve our services.
              </span>
              <br />
              Local storage:
              <span className={styles.termsDescription}>
                &nbsp; The SaaS partly stores data locally on your device (e.g.
                in the web memory of your browser), e.g. to provide real-time
                feedback on your communication behavior. This data is usually
                deleted automatically by your device.
              </span>
              <br />
              <br />
            </li>
            <li className={styles.rightsUnderDdpr}>
              Third Party Technology
              <br />
              <br />
              Google or LinkedIn Sign-In
              <br />
              <span className={styles.termsDescription}>
                For access to our SaaS platform (for example, the login via
                <a href="https://dashboard.interviewer.ai/">
                  https://dashboard.interviewer.ai/
                </a>
                {' '}
                we may offer a login in the future via Google or LinkedIn.
              </span>
              <br />
              <br />
              Website
              <br />
              <span className={styles.termsDescription}>
                You can visit most areas of the Interviewer.AI website without
                disclosing any Personal Data. Interviewer.AI only logs the
                domain name, IP address and browser type of our website visitors
                via our webserver log les and analytics tools at irregular
                intervals. We use this information to log global access to our
                website. For our SaaS we will ask that you provide sign-up an
                Account at Interviewer.AI – see above “Account”. In some cases,
                you will be unable to complete a particular step in the process
                if you do not want to provide the information requested. For
                example, we will ask for Personal Data in the following
                instances: If you use our online technical support or ask
                questions about our products and services using the contact form
                or the contact options on our website, we will ask you to give
                us Personal Data required for processing the support request.
                This can include your email address, name and address, as well
                as information about your computer hardware and software and the
                type of problem you have. If you have requested a test license
                on our website, in order to activate such license, you may have
                to provide Personal Data, such as an email address. If you
                request a service from us via our website. If you participate in
                a survey on our website. If you subscribe to a newsletter. If
                you signed up for a newsletter and no longer want to receive it,
                you can unsubscribe at any time by using the unsubscribe option
                provided in the email or by sending us an email using the
                contact information provided below.
              </span>
              <br />
              <br />
              Use of Cookies
              <span className={styles.termsDescription}>
                <br />
                Cookies are small text files that are stored on your computer or
                in your browser. Cookies are loaded on your browser when you
                first use a product or service. Cookies do not include any
                Personal Data. We may use cookies to identify the browser you
                are using so that our website displays properly. We also use
                cookies in various places on our website in order to document
                your visit to the Interviewer.AI website and allow for a more
                efficient website design. For example, to optimize the shopping
                cart feature used for ordering in the Interviewer.AI online
                store.
                <br />
                If you do not want to allow Interviewer.AI or someone else to
                use cookies, you can disable cookie installation via your
                browser setting. While you also have the option of deleting
                cookies from your computer’s hard disk at any time, if you
                choose to do so, you may be unable to use some of the features
                of our website, products and services.
              </span>
              <br />
              <br />
              User Experience and Statistics
              <span className={styles.termsDescription}>
                <br />
                We use our own technology (including soft authentication
                systems, storage and messaging engines, databases and big data
                repositories) to perform statistical evaluations using
                pseudonymized user profiles. We do this to optimize your
                experience with our products and provide relevant content and
                advertisements.
                <br />
                We also use the information we collect to offer you content,
                search results and advertisements that will be of most interest
                to you. We believe more relevant advertising provides a better
                Internet experience. This is also how we support our business
                while still providing certain products or services to you free
                of charge. As part of this process, we may collect information
                about how you use and interact with the advertisements that we
                offer. We may also use information about how you use our
                products and services across various Devices. Based on this
                Device-specific information (for example, the hardware model,
                operating system, version, device, mobile network information
                including your phone number)and the unique application number
                assigned to some of our products and services, we may be able to
                compile a personal pro le about you. When you log in and access
                your Account or our products, we may automatically collect and
                store certain information. For example, details of how you use
                our products, what kind of search queries you conduct, where
                your computer is routing from, your browser type and version,
                and Cookies that may identify your browser and/or Account.
                <br />
                By reviewing and accepting this privacy policy, you understand
                and agree that we may combine non-Personal Data or pseudonymized
                data obtained via our proprietary soft authentication systems
                with Personal Data obtained when you sign up for an
                Interviewer.AI Account. We do not share this combined data with
                third parties. In addition to our own proprietary technology, we
                use the following third-party analytics tools to better
                understand your interaction with our website and use of our
                products/services.
              </span>
              <br />
              <br />
              Google Analytics
              <span className={styles.termsDescription}>
                <br />
                We use Google Analytics, a web analysis service from Google,
                Inc. (“Google”). Google Analytics uses Cookies to make it
                possible to analyze your use of our website. Information about
                your use of our website the Cookie generates is usually
                transferred to a Google server in the USA and saved there.
                However, before this happens, Google shortens and anonymizes
                your IP address (Google’s anonymize Ip process) if located
                within a member state of the European Union or in other
                contracting member states to the Agreement on the European
                Economic Area. The entire IP address is transferred to a Google
                server in the USA and saved there only in exceptional cases.
                This anonymization ensures that your IP address cannot be traced
                back to you. Google will use this information to evaluate your
                use of the website in order to compile reports about website
                activities for Interviewer.AI and provide additional services
                associated with website and Internet usage. Google can transfer
                this information to third parties, where appropriate, if legally
                mandated or if Google contracts with third parties to process
                such data. Google will not associate your IP address with other
                Google data. By using our website, you expressly consent to
                Google’s collection and storage of your data in the manner and
                for the purposes described above. You also have the option to
                prevent Google from acquiring and processing data generated by
                Cookies and data related to your use of our website (including
                your IP address) by downloading and installing a Google-provided
                browser plugin. More information about Google Analytics can be
                found here(
                <a href="https://www.google.com/analytics/learn/privacy.html">
                  https://www.google.com/analytics/learn/privacy.html
                </a>
                ).
              </span>
              <br />
              <br />
              LinkedIn Pixel
              <span className={styles.termsDescription}>
                <br />
                We use the “LinkedIn pixel” from LinkedIn Ireland. This allows
                users’ behaviour to be tracked after they have seen or clicked
                on a LinkedIn ad. This process is used to evaluate the
                effectiveness of LinkedIn advertisements for statistical and
                market research purposes and can help to optimise advertising
                measures. The data collected is anonymous to us, so it does not
                give us any indication of the identity of the users.
              </span>
            </li>
            <br />
            <li className={styles.rightsUnderDdpr}>
              Rights of Data Subjects
              <br />
              <span className={styles.termsDescription}>
                According to Art. 15 GDPR you have the right to obtain from the
                data controller free information on request about the personal
                data stored about you as well as the purpose of the data
                processing. Please take note that the respective employer is the
                data controller of the data processed during an Video Recruiting
                process. According to articles 16, 17 and 18 GDPR you also have
                the right to correct incorrect data and block and delete your
                personal data. Moreover according to Art. 20 GDPR, you have the
                right to receive the personal data concerning you which you
                provided to us, in a structured, commonly used and
                machine-readable format and the right to transmit those data to
                another controller without hindrance from our part. According to
                Art. 21 (1) GDPR, you also have the right to object, on the
                basis of your particular situation, at any time to processing
                your personal data as far as it is based on Art. 6 (1) e) or f)
                GDPR. We will comply with the aforementioned requests if and to
                the extent such compliance is required by the applicable
                statutory laws. Requests for access to and rectification or
                erasure of personal data or restriction of processing may be
                directed to the email or post address stated in our website’s
                imprint. Each data subject has the right to lodge a complaint
                with a supervisory authority of the alleged infringement if the
                data subject considers that the processing of personal data
                relating to him or her infringes the GDPR.
              </span>
              <br />
              <br />
            </li>
          </ol>
          <ol>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Binding Agreement</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>1.1</span>
                {' '}
                &nbsp;Interviewer.AI Pte. Ltd. (Singapore UEN 201837827Z)
                (“Interviewer.AI”, “we”, “us”, and “our”) provides a platform
                that facilitates interactions between potential employers and
                job candidates. The terms of this Agreement are between you and
                Interviewer.AI, and your access and use of the Platform
                constitutes your consent to this Agreement and its terms.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>1.2</span>
                &nbsp;
                We may update the terms of this Agreement from time to time. We
                will notify you of such changes either via the email address
                provided by you on registration or through an announcement on
                the Platform. The changes will apply to the access and use of
                the Platform upon such notice. Your continued access and use of
                the Platform after such notice has been given indicates your
                agreement to be bound by the new terms.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Definitions</p>
              <p className={styles.termsDescription}>
                In this Agreement, the following definitions apply:
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>“Candidate”</span>
                  &nbsp; means a user who creates a Candidate Profile for
                  accessing and using the Platform to search for employment
                  opportunities or to participate in an application for
                  employment with an Employer.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>
                    “Candidate Profile”
                  </span>
                  &nbsp; means a profile created by a Candidate that may include
                  personal details, resume, photo, video, skills, and
                  professional interests.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>“Employer”</span>
                  &nbsp; means a user who is accessing or using the Platform for
                  any reason related to the purpose of seeking, screening or
                  assessing candidates for employment.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>
                    “Intellectual Property Rights”
                  </span>
                  &nbsp; means all patents, trade names, trade marks, service
                  marks, logos, design rights, copyrights, trade secrets,
                  database rights, rights in technology, knowhow, or other
                  intellectual property rights (whether registered or
                  unregistered) that are protected under any applicable law, and
                  all applications, renewals and extensions of the same.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>
                    “Personal Data”
                  </span>
                  &nbsp; means data, whether true or not, about an individual
                  who can be identified (a) from that data; or (b) from that
                  data and other information to which we have or are likely to
                  have access.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>“Platform”</span>
                  &nbsp; means the Interviewer.AI platform and any service or
                  feature provided thereon by Interviewer.AI or its affiliates
                  or agents, whether directly or indirectly, including through
                  application programming interface (API) integration and/or
                  hyperlinks.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>“SIAC”</span>
                  &nbsp; has the meaning ascribed to it in Clause 16.1.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>“SIMC”</span>
                  &nbsp; has the meaning ascribed to it in Clause 16.5.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>“User Content”</span>
                  &nbsp; means all information, including but not limited to
                  data, text, videos, photographs, graphics, advertisements,
                  messages, music, or other materials submitted, posted,
                  displayed or otherwise provided by users on or through the
                  Platform, and includes job postings by Employers and Candidate
                  Profiles.
                </p>
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Registration</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>3.1</span>
                &nbsp; You must be at least 18 years of age to register an account on the
                Platform.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>3.2</span>
                &nbsp; On registration, you will be asked to create a Candidate Profile.
                You must ensure that the details provided by you on registration
                or at any time thereafter are accurate, up to date, and
                complete.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>3.3</span>
                &nbsp;We reserve the right to delete your account and all of your
                information after a period of inactivity, but in any case only
                after we have provided you with notice accordingly.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>3.4</span>
                &nbsp; You may cancel your registration with the Platform at any time by
                informing us in writing. Upon cancellation of your registration,
                you agree to stop accessing and using the Platform.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Password and Security</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>4.1</span>
                &nbsp; You agree to keep your login details confidential and not to
                disclose it to any third party.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>4.2</span>
                &nbsp; You are strictly liable and responsible for all uses of your account
                and password, whether or not authorised by you.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>4.3</span>
                &nbsp; You shall notify us immediately if you know or suspect that your
                account has been compromised.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>4.4</span>
                &nbsp; If we have reason to believe that there is likely a breach of your
                account or misuse of the Platform using your account, we may
                require you to reset your password or may suspend your account.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>4.5</span>
                &nbsp; As a result of your loss of your login details or the misuse of the
                Platform, you shall:
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>a)</span>
                  &nbsp; bear all losses or damage incurred thereby; and
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>b)</span>
                  &nbsp;
                  fully indemnify Interviewer.AI should Interviewer.AI suffer
                  any loss or damage.
                </p>
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Use of Platform</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>5.1</span>
                &nbsp;
                Interviewer.AI hereby grants you a limited, terminable,
                non-exclusive right to access and use the Platform for your
                personal, non-commercial purposes as follows:
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>a)</span>
                  &nbsp;
                  seeking employment opportunities;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>b)</span>
                  &nbsp;
                  applying for jobs; and
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>c)</span>
                  &nbsp; any other purposes reasonably related to the purposes specified
                  above.
                </p>
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>5.2</span>
                &nbsp; Your permission to access and use the Platform is personal to you and
                is non-transferrable and non-assignable.As part of the
                application process for employment with an Employer, you may be
                required to participate in activities such as video and audio
                interviews, psychometric assessments, and gaming tests. You
                acknowledge and agree that your participation in such activities
                on the Platform may be recorded, and you hereby consent to such
                recording, including the use of your name, likeness, voice and
                persona in connection with your use of the Platform, and to
                Interviewer.AI and the relevant Employer’s access to and use of
                any such recordings and your Candidate Profile in accordance
                with Clause 9 (Personal Data).
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>5.3</span>
                &nbsp; You may not access or use the Platform:
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>a)</span>
                  &nbsp; for any unlawful or fraudulent purpose;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>b)</span>
                  &nbsp; to disseminate or publish any content that is false, inaccurate,
                  unlawful, harassing, defamatory, misleading, abusive,
                  threatening, harmful, violent, obscene, vulgar, or otherwise
                  objectionable;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>c)</span>
                  &nbsp; to disseminate or publish any content that is false, inaccurate,
                  unlawful, harassing, defamatory, misleading, abusive,
                  threatening, harmful, violent, obscene, vulgar, or otherwise
                  objectionable;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>d)</span>
                  &nbsp; to modify, adapt, translate, reverse engineer, decompile, or
                  disassemble any part of the Platform without our prior
                  consent;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>e)</span>
                  &nbsp; to interfere with any other person’s access to or use of the
                  Platform;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>f)</span>
                  &nbsp; to post any content or apply for any job on behalf of another
                  party;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>g)</span>
                  &nbsp; to access information not intended for you, or to access a server
                  or account which you are not authorised to access;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>h)</span>
                  &nbsp; to interfere with or disrupt the operation of the Platform, or
                  the servers or networks used to make the Platform available or
                  violate any requirements, procedures, policies or regulations
                  of such networks;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>i)</span>
                  &nbsp; to create a database by systematically downloading and storing
                  content available on the Platform;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>j)</span>
                  &nbsp; to sell, promote or advertise products or services; and
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>k)</span>
                  &nbsp; in any manner in breach of this Agreement.
                </p>
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>5.4</span>
                &nbsp;We reserve the right to waive any of the conditions in Clause 5.4
                either generally or in specific instances.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>5.5</span>
                &nbsp;We may, but are not obliged to, remove, edit, suspend, block,
                and/or monitor user accounts that we determine, in our sole
                discretion, violates the terms of this Agreement or any
                applicable law.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Availability of Services</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>6.1</span>
                &nbsp;We will apply reasonable efforts to ensure that the Platform is
                available at all times. However, we cannot guarantee that the
                Platform or any part or feature thereof will always be available
                or error-free although we will attempt to correct the problem as
                soon as reasonably possible.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>6.2</span>
                &nbsp;Access to the Platform may be restricted from time to time to allow for
                the repair, maintenance, or update of the Platform. We will
                attempt to restore access to the Platform as soon as we
                reasonably can.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Intellectual Property</p>
              <p className={styles.termsDescription}>
                Subject to Clause 8 (User Content), all Intellectual Property
                Rights in the Platform and all of the text, pictures, videos,
                graphics, user interfaces, visual interfaces, trademarks, logos,
                applications, programs, computer code and other content made
                available on it are owned by Interviewer.AI. You may not
                reproduce, modify, distribute or otherwise use for commercial
                purposes any such content without our written consent, except as
                provided in this Agreement or permitted by law.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>User Content</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>8.1</span>
                &nbsp; We do not claim ownership of any User Content that you provide on or
                through our Platform. You represent and warrant that you own or
                are otherwise authorised to use in any manner such User Content
                provided by you.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>8.2</span>
                &nbsp; We do not represent, endorse, or guarantee the accuracy, veracity, or
                reliability of User Content (whether provided by you or other
                persons) or derivative works therefrom. Any reliance on User
                Content will be at your own risk.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>8.3</span>
                &nbsp; You acknowledge and understand that all User Content is the sole
                responsibility of the person from whom the User Content
                originated.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>8.4</span>
                &nbsp; By submitting, posting, displaying or otherwise providing User
                Content on or through the Platform, you grant Interviewer.AI a
                worldwide, non-exclusive, royalty-free licence to reproduce,
                adapt, distribute, and publish the User Content in its sole
                discretion and in accordance with Clause 9 (Personal Data).
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>8.5</span>
                &nbsp; We may review and remove any User Content that we determine, in our
                sole discretion, violates the terms of this Agreement or any
                applicable law.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Personal Data</p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>9.1</span>
                {' '}
                &nbsp;Collection of Personal Data
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.1.1</span>
                &nbsp; We may collect your Personal Data in the following instance for
                which you hereby consent to:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp; when you provide it to us (e.g. by registering an account with the
                Platform or updating your Candidate Profile);
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp;
                automatically when you access or use the Platform; and from
                third parties (including an Employer with whom you have applied
                to for employment, whether or not through the Platform) but only
                where we have made reasonable efforts to verify that the third
                parties either have your consent or are otherwise legally
                permitted or required to disclose your Personal Data to us.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.1.2</span>
                &nbsp; The Personal Data that we may collect includes, but is not limited
                to:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp; name, gender, date of birth, nationality, country and city of birth;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp;
                mailing address, telephone numbers, email address, and other
                contact details;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>c)</span>
                &nbsp;
                resume, educational and professional qualifications and
                certifications, and employment references;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>d)</span>
                &nbsp;
                employment and training history;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>e)</span>
                &nbsp;
                professional interests;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>f)</span>
                &nbsp;
                work-related health issues and disabilities;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>g)</span>
                &nbsp;
                photographs and videos;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>h)</span>
                &nbsp;
                usage data from your access and use of the Platform;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>i)</span>
                &nbsp;
                interview recordings and information you provide in online
                assessments conducted using the Platform.
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>9.2</span>
                {' '}
                &nbsp;Purpose of collecting and using Personal Data
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.2.1</span>
                &nbsp;
                Your Personal Data may be collected and used by us for the
                following purposes:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp;
                to operate, maintain, enhance, develop, test, provide, and monitor
                the effectiveness of the Platform;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp; to
                verify your identity and manage your registration with the
                Platform;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>c)</span>
                &nbsp; to
                facilitate any job application process between you and a
                Employer;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>d)</span>
                &nbsp; to
                personalise the Platform for your preferences and interests;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>e)</span>
                &nbsp; to
                respond to, handle, and process queries, requests, applications,
                complaints, and feedback from you;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>f)</span>
                &nbsp; to
                contact you for administrative purposes;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>g)</span>
                &nbsp; where
                in accordance with your preferences, to notify you of, or to
                enable potential Employers to contact you regarding employment
                opportunities we think you may be interested in;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>h)</span>
                &nbsp; to
                understand and analyse the usage trends, user preferences and
                interests;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>i)</span>
                &nbsp; to
                diagnose and troubleshoot errors on the Platform;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>j)</span>
                &nbsp; to
                detect and monitor illegal activity;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>k)</span>
                &nbsp; any
                other incidental business purposes related to or in connection
                with the above;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>i)</span>
                &nbsp; where
                we are required to do so by law.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.2.2</span>
                &nbsp; We
                may also disclose your Personal Data for the purposes specified
                in Clause 9.2.1 to the following persons:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp;
                Employers to whom you are applying for a job, and any of their
                affiliates or agents who are involved in the job application
                process;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp;
                potential Employers who may have employment opportunities which
                match your preferences;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>c)</span>
                &nbsp;
                employees of Interviewer.AI, but only where necessary for the
                performance of their duties;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>d)</span>
                &nbsp; any
                competent law enforcement or other government authorities
                (whether within or outside Singapore) as may be permitted or
                required by any applicable law or in relation to any court order
                or judgment;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>e)</span>
                &nbsp; to
                third-party service providers, agents, and other organisations
                that perform services on our behalf, including web-hosts,
                mailing services, market research and web usage analysis
                services, and maintenance and repair services;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>f)</span>
                &nbsp;
                otherwise as permitted under any data protection legislation.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.2.3</span>
                &nbsp; We
                will seek your consent where the collection, use, and disclosure
                of your Personal Data falls outside the scope of Clause 9.2.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.2.4 </span>
                &nbsp;
                The purposes listed in Clause 9.2 may continue to apply even in
                situations where your relationship with us has been terminated
                or altered in any way, for a reasonable period thereafter
                (including, where applicable, a period to enable us to enforce
                our rights under or arising from this Agreement with you).
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>9.3</span>
                {' '}
                &nbsp;Withdrawal of consent
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.3.1 </span>
                &nbsp;
                The consent that you provide for the collection, use, and
                disclosure of your Personal Data in accordance with Clause 9.2
                will remain valid until such time as it is withdrawn by you in
                writing.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.3.2 </span>
                &nbsp;
                Upon receipt of your written request to withdraw your consent,
                we may require reasonable time for your request to be processed
                and for us to notify you of the consequences of us acceding to
                the same, including any legal consequences which may affect your
                rights and liabilities to us.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.3.3 </span>
                &nbsp;
                Whilst we respect your decision to withdraw your consent, please
                note that such withdrawal does not affect disclosures of your
                Personal Data that we had already disclosed or otherwise
                provided access to the persons listed in Clause 9.2.2.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.3.4 </span>
                &nbsp;
                The withdrawal of your consent does not affect our right to
                continue to collect, use, and disclose Personal Data where such
                collection, use, and disclosure without consent is permitted or
                required under the applicable laws.
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>9.4</span>
                &nbsp;Access
                to and correction of Personal Data
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.4.1 </span>
                &nbsp;
                Should you wish to access a copy of the Personal Data which we
                hold about you or correct any of your Personal Data which we
                hold about you, you may submit a written request to our Data
                Protection Officer at the contact details provided in Clause 9.8
                below.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.4.2 </span>
                &nbsp;
                Please note that a reasonable fee may be charged for such
                request. We will inform you of the fee before processing your
                request.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.4.3 </span>
                &nbsp; We
                will aim to respond to your access request as soon as reasonably
                possible. If we are unable to respond to your access request
                within 30 days after receiving the request, we will inform you
                in writing within 30 days of the time by which we will be able
                to respond to your request. If we are unable to provide you with
                any Personal Data requested by you, we will generally inform you
                of the reasons why we are unable to do so (except where we are
                not required to do so under the applicable laws).
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>9.5</span>
                {' '}
                &nbsp;Retention of Personal Data
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.5.1 </span>
                &nbsp; We
                will retain your Personal Data for the period necessary to
                fulfil the purposes for which they were collected, or as
                required or permitted by applicable laws.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.5.2 </span>
                &nbsp; We
                will cease to retain your Personal Data, or remove the means by
                which the Personal Data can be associated with you, as soon as
                it is reasonable to assume that such retention no longer serves
                the purposes for which the Personal Data was collected, and is
                no longer necessary for legal or business purposes.
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>9.6</span>
                &nbsp;
                Security of Personal Data
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.6.1 </span>
                &nbsp; To
                safeguard your Personal Data from unauthorised access,
                collection, use, disclosure, copying, modification, disposal, or
                similar risks, we will use reasonably appropriate
                administrative, physical, and technical measures to secure all
                storage and transmission of Personal Data by us.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.6.2 </span>
                &nbsp; No
                method of transmission over the Internet or method of electronic
                storage is completely secure. Although security cannot be
                guaranteed, we strive to protect the security of your Personal
                Data and will periodically review and enhance our information
                security measures.
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>9.7</span>
                &nbsp;
                Transfer of Personal Data outside Singapore
              </p>
              <p className={styles.termsDescription}>
                It may be necessary for us to transfer your Personal Data
                outside of Singapore, such as for the purposes of storing and/or
                processing. If we do so, we will take steps to ensure that your
                Personal Data continues to receive a standard of protection that
                is at least comparable to that provided under the Personal Data
                Protection Act (No. 26 of 2012).
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Third Party Websites</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>10.1</span>
                &nbsp; The
                Platform may contain links to other online resources and
                locations. We are not responsible for, and do not endorse, the
                content of these external resources or locations.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>10.2</span>
                &nbsp; You
                may create a link to the Platform subject to the following
                conditions:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp; the
                link is not presented in a way that is misleading or that may
                suggest any form of endorsement, approval, or association by us
                that does not exist, or is harmful to our reputation or the
                reputation of any of our affiliates;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp; you
                shall immediately comply with any request by us to remove the
                link.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Term and Termination</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.1</span>
                &nbsp; The
                terms of this Agreement remain in full force and effect so long
                as you access or use the Platform.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.2</span>
                &nbsp; We
                reserve the right at our sole discretion to pursue all legal
                action, including but not limited to removing your content from
                the Platform and terminating with immediate effect your access
                to the Platform, upon any breach by you of this Agreement.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.3</span>
                &nbsp;
                Clauses 7 (Intellectual Property), 11 (Term and Termination), 12
                (Limitation of Liability), 13 (Disclaimers), and 14 (Indemnity)
                inclusive remain in full force and effect even after you are no
                longer a user of the Platform.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Limitation of Liability</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>12.1</span>
                &nbsp; The
                Platform is provided on an “as is” basis, and we make no
                representations or warranty as to the quality, completeness, or
                accuracy of any content made available on the Platform.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>12.2</span>
                &nbsp; To
                the maximum extent permitted by law, we expressly exclude:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp; all
                conditions, warranties, and other terms that might otherwise be
                implied by law into this Agreement;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp; any
                and all liability to you, whether arising from this Agreement or
                otherwise in connection with your access to and use of the
                Platform.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>12.3)</span>
                &nbsp; We
                shall not be liable for any consequential, special, incidental,
                or indirect damage or loss of any kind arising from our
                provision of the Platform or any access or use of the Platform
                by any person, even if we have been advised of the possibility
                of such damage or loss. For the avoidance of doubt, we shall not
                in any event be liable for loss of employment opportunity.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>12.4)</span>
                &nbsp; To
                the maximum extent permitted by law, our aggregate liability to
                you for all claims arising from your use of the Platform shall
                be limited to S$[100].
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Disclaimer</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>13.1)</span>
                &nbsp; You
                acknowledge and agree that Interviewer.AI is solely a platform
                provider and does not participate in the interview, selection,
                or hiring of Candidates, which are the sole responsibility of
                the Employer, notwithstanding the use of the Platform for and in
                connection with such activities. It is the Employer’s sole
                responsibility to comply with all applicable laws regarding its
                use of the Platform for such activities, including but not
                limited to all applicable employment and hiring laws and
                regulations.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>13.2)</span>
                &nbsp; We
                shall not be liable for any damage or loss suffered by you as a
                result of any employment decision or breach of applicable laws
                and regulations by the Employer.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>13.3)</span>
                &nbsp; We
                shall not be liable for any loss of information howsoever caused
                as a result of any interruption, suspension, or termination of
                the Platform or its services, or for the content, accuracy, or
                quality of content on or transmitted through the Platform.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>13.4)</span>
                &nbsp; We
                shall not be liable for any risks associated with dealing with
                other users with whom you come into contact with through the
                Platform, including but not limited to, the risk of physical
                harm, and you agree to assume all such risks if you decide to
                initiate such contact with other users of the Platform.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>13.5)</span>
                &nbsp;
                Nothing on the Platform is considered our endorsement,
                representation, or warranty with respect to any user or third
                party, whether of its reputation, websites, products, services,
                hiring, experience, employment or recruiting practices, or
                otherwise.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Indemnity</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>14.1)</span>
                &nbsp; To
                the maximum extent permitted by law, you agree to defend,
                indemnify, and hold harmless Interviewer.AI, our affiliates, and
                our or their respective directors, officers, employees, and
                agents, from and against any claims, actions, or demands,
                including reasonable legal and accounting fees, alleging or
                resulting from:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp; any
                content you provided on or through the Platform;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp; your
                use of the Platform or any content thereon;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>c)</span>
                &nbsp; your
                breach of any term of this Agreement.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>14.2</span>
                &nbsp; We
                will provide you with notice of any such claim, action, or
                demand as soon as reasonably possible.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Applicable Law</p>
              <p className={styles.termsDescription}>
                The terms of this Agreement and any non-contractual obligation
                arising out of or in connection with this Agreement shall be
                governed by, and construed in accordance with, the laws of
                Singapore.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Dispute Resolution</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>16.1</span>
                &nbsp; Any
                dispute arising out of or in connection with this Agreement,
                including any question regarding its existence, validity or
                termination, shall be referred to and finally resolved by
                arbitration administered by the Singapore International
                Arbitration Centre (“SIAC”) in accordance with the Arbitration
                Rules of the SIAC for the time being in force, which rules are
                deemed to be incorporated by reference in this clause.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>16.2</span>
                &nbsp; The
                seat of the arbitration shall be Singapore.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>16.3</span>
                &nbsp; The
                Tribunal shall consist of one (1) arbitrator to be appointed by
                the chairman for the time being of the SIAC.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>16.4</span>
                &nbsp; The
                language of the arbitration shall be English.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>16.5</span>
                &nbsp; You
                further agree that following the commencement of arbitration,
                you will attempt in good faith to resolve the dispute through
                mediation at the Singapore International Mediation Centre
                (“SIMC”), in accordance with the SIAC-SIMC Arb-Med-Arb Protocol
                for the time being in force. Any settlement reached in the
                course of the mediation shall be referred to the arbitral
                tribunal appointed by SIAC and may be made a consent award on
                agreed terms.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Miscellaneous</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>17.1</span>
                &nbsp; You
                may not assign or transfer any of your rights under this
                Agreement to any other person. You agree that we may assign or
                transfer our rights under this Agreement at our sole discretion,
                where we reasonably believe that your rights will not be
                affected.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>17.2</span>
                &nbsp; No
                term of this Agreement shall be enforceable solely by virtue of
                the Contracts (Rights of Third Parties) Act (Chapter 53B of
                Singapore) by any person who is not a party to this Agreement.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>17.3</span>
                &nbsp; If
                we choose not to enforce any rights or remedies against you
                under this Agreement, we remain entitled to enforce those rights
                or remedies at a later date, or in any other situation where you
                breach any term of this Agreement. The rights provided in this
                Agreement are cumulative and not exclusive of any rights or
                remedies provided by law.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>17.4</span>
                &nbsp; If
                any provision of this Agreement shall be invalid, illegal, or
                unenforceable in any respect by operation of law or otherwise is
                or becomes ineffective for reasons beyond our control, the
                validity, legality and enforceability of the remaining
                provisions of this Agreement shall not in any way be affected or
                impaired.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>17.5</span>
                &nbsp; If
                you have any questions, please contact us at
                hello@interviewer.ai.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <div className={isMobile ? styles.buttonForMobile : styles.button}>
        <button
          className={isMobile ? styles.btnForMobile : styles.btn}
          type="button"
          onClick={handleClick}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default TermsOfConditions;
